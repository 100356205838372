<template>
  <el-container style="height: 100%">
    <el-header style="padding: 0; height: 40px">
      <div id="navigation">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/myFolder' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item @click.native="goBack"
            ><a>{{ imgInfo.deviceObj.name }}</a></el-breadcrumb-item
          >
          <el-breadcrumb-item>单图分析</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </el-header>

    <el-container style="height: calc(100% - 40px); background: #f4f4f4" @mousemove.native="sliderMouseMove" @mouseup.native="moveUp">
      <el-aside width="46px" class="info-aside" style="overflow: hidden; background: white">
        <div style="width: 62px; height: 100%; overflow-y: auto; overflow-x: hidden">
          <div class="button-line">
            <div class="button-img" v-bind:title="'指针'" @click="functClick('select')">
              <el-image
                style="width: 100%; height: 100%"
                :src="require(`@/assets/img/analysis/functionIcon/${currentFunct === 'select' ? 'mouse_sel' : 'mouse_def'}.png`)"
                fit="contain"
              ></el-image>
            </div>
            <div class="button-img" v-bind:title="'字体设置'" @click="functClick('font')">
              <el-image style="width: 100%; height: 100%" :src="require(`@/assets/img/analysis/functionIcon/font_def.png`)" fit="contain"></el-image>
            </div>
            <el-dialog title="显示设置" :visible.sync="fontDialogVisible" destroy-on-close custom-class="analysis-font-dialog" top="28vh">
              <el-form>
                <el-form-item label="字体颜色">
                  <el-color-picker v-model="fontColor" @change="styleChange"></el-color-picker>
                </el-form-item>
                <el-form-item label="字体大小">
                  <div style="margin-left: 72px">
                    <el-slider v-model="fontSize" :min="10" :max="20" @change="styleChange"></el-slider>
                  </div>
                </el-form-item>
                <el-form-item label="温度显示设置">
                  <el-checkbox-group v-model="tempTypeCheckList" @change="styleChange">
                    <el-checkbox label="max">最高温</el-checkbox>
                    <el-checkbox label="min">最低温</el-checkbox>
                    <el-checkbox label="avg">平均温</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item label="温度显示位置">
                  <el-radio-group v-model="tempShowLocation" @change="styleChange">
                    <el-radio label="nrml">正常</el-radio>
                    <el-radio label="left">左侧</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-form>
            </el-dialog>
          </div>
          <div class="button-line">
            <div class="button-img" v-bind:title="'绘制测温点'" @click="functClick('point')">
              <el-image
                style="width: 100%; height: 100%"
                :src="require(`@/assets/img/analysis/functionIcon/${currentFunct === 'point' ? 'point_sel' : 'point_def'}.png`)"
                fit="contain"
              ></el-image>
            </div>
            <div class="button-img" v-bind:title="'绘制测温直线'" @click="functClick('line')">
              <el-image
                style="width: 100%; height: 100%"
                :src="require(`@/assets/img/analysis/functionIcon/${currentFunct === 'line' ? 'line_sel' : 'line_def'}.png`)"
                fit="contain"
              ></el-image>
            </div>
          </div>
          <div class="button-line">
            <div class="button-img" v-bind:title="'绘制测温矩形'" @click="functClick('rectangle')">
              <el-image
                style="width: 100%; height: 100%"
                :src="require(`@/assets/img/analysis/functionIcon/${currentFunct === 'rectangle' ? 'rectangle_sel' : 'rectangle_def'}.png`)"
                fit="contain"
              ></el-image>
            </div>
            <div class="button-img" v-bind:title="'绘制测温圆形'" @click="functClick('circle')">
              <el-image
                style="width: 100%; height: 100%"
                :src="require(`@/assets/img/analysis/functionIcon/${currentFunct === 'circle' ? 'circle_sel' : 'circle_def'}.png`)"
                fit="contain"
              ></el-image>
            </div>
          </div>
          <div class="button-line">
            <div class="button-img" v-bind:title="'绘制测温多边形'" @click="functClick('polygon')">
              <el-image
                style="width: 100%; height: 100%"
                :src="require(`@/assets/img/analysis/functionIcon/${currentFunct === 'polygon' ? 'polygon_sel' : 'polygon_def'}.png`)"
                fit="contain"
              ></el-image>
            </div>

            <el-popover placement="right" trigger="click">
              <div class="popover-content" v-if="params.gwType === 'PX1'">
                <div class="pseudo-color" v-bind:title="'白热'" @mouseover="colorOver(1)">
                  <el-image style="height: 50px" :src="require(`@/assets/img/analysis/pseudoColor/PX1/1whitehot.png`)" fit="contain"></el-image>
                </div>
                <div class="pseudo-color" v-bind:title="'黑热'" @mouseover="colorOver(2)">
                  <el-image style="height: 50px" :src="require(`@/assets/img/analysis/pseudoColor/PX1/2blackhot.png`)" fit="contain"></el-image>
                </div>
                <div class="pseudo-color" v-bind:title="'铁红'" @mouseover="colorOver(3)">
                  <el-image style="height: 50px" :src="require(`@/assets/img/analysis/pseudoColor/PX1/3iron.png`)" fit="contain"></el-image>
                </div>
                <div class="pseudo-color" v-bind:title="'冷白'" @mouseover="colorOver(4)">
                  <el-image style="height: 50px" :src="require(`@/assets/img/analysis/pseudoColor/PX1/4coldwhite.png`)" fit="contain"></el-image>
                </div>
                <div class="pseudo-color" v-bind:title="'黑冷'" @mouseover="colorOver(5)">
                  <el-image style="height: 50px" :src="require(`@/assets/img/analysis/pseudoColor/PX1/5blackcold.png`)" fit="contain"></el-image>
                </div>
                <div class="pseudo-color" v-bind:title="'红热'" @mouseover="colorOver(6)">
                  <el-image
                    style="height: 50px; width: 37.5px"
                    :src="require(`@/assets/img/analysis/pseudoColor/PX1/6blackred.png`)"
                    fit="cover"
                  ></el-image>
                </div>
                <div class="pseudo-color" v-bind:title="'彩虹'" @mouseover="colorOver(7)">
                  <el-image style="height: 50px" :src="require(`@/assets/img/analysis/pseudoColor/PX1/7rainbow.png`)" fit="contain"></el-image>
                </div>
                <div class="pseudo-color" v-bind:title="'冰火'" @mouseover="colorOver(8)">
                  <el-image
                    style="height: 50px; width: 37.5px"
                    :src="require(`@/assets/img/analysis/pseudoColor/PX1/8icefire.png`)"
                    fit="cover"
                  ></el-image>
                </div>
                <div class="pseudo-color" v-bind:title="'夜视绿'" @mouseover="colorOver(9)">
                  <el-image style="height: 50px" :src="require(`@/assets/img/analysis/pseudoColor/PX1/9green.png`)" fit="contain"></el-image>
                </div>
              </div>
              <div v-else class="popover-content">
                <div class="pseudo-color" v-bind:title="'黑热'" @mouseover="colorOver(0)">
                  <el-image style="height: 50px" :src="require(`@/assets/img/analysis/pseudoColor/color0.png`)" fit="contain"></el-image>
                </div>
                <div class="pseudo-color" v-bind:title="'白热'" @mouseover="colorOver(1)">
                  <el-image style="height: 50px" :src="require(`@/assets/img/analysis/pseudoColor/color1.png`)" fit="contain"></el-image>
                </div>
                <div class="pseudo-color" v-bind:title="'彩虹'" @mouseover="colorOver(2)">
                  <el-image style="height: 50px" :src="require(`@/assets/img/analysis/pseudoColor/color2.png`)" fit="contain"></el-image>
                </div>
                <div class="pseudo-color" v-bind:title="'彩虹HC'" @mouseover="colorOver(3)">
                  <el-image style="height: 50px" :src="require(`@/assets/img/analysis/pseudoColor/color3.png`)" fit="contain"></el-image>
                </div>
                <div class="pseudo-color" v-bind:title="'铁红'" @mouseover="colorOver(4)">
                  <el-image style="height: 50px" :src="require(`@/assets/img/analysis/pseudoColor/color4.png`)" fit="contain"></el-image>
                </div>
                <div class="pseudo-color" v-bind:title="'熔岩'" @mouseover="colorOver(5)">
                  <el-image style="height: 50px" :src="require(`@/assets/img/analysis/pseudoColor/color5.png`)" fit="contain"></el-image>
                </div>
                <!-- <div class="pseudo-color" v-bind:title="''" @mouseover="colorOver(7)">
                <el-image style="height: 50px" :src="require(`@/assets/img/analysis/pseudoColor/color7.png`)" fit="contain"></el-image>
              </div> -->
                <div class="pseudo-color" v-bind:title="'灰红'" @mouseover="colorOver(8)">
                  <el-image style="height: 50px" :src="require(`@/assets/img/analysis/pseudoColor/color8.png`)" fit="contain"></el-image>
                </div>
                <div class="pseudo-color" v-bind:title="'紫橙'" @mouseover="colorOver(9)">
                  <el-image style="height: 50px" :src="require(`@/assets/img/analysis/pseudoColor/color9.png`)" fit="contain"></el-image>
                </div>
                <div class="pseudo-color" v-bind:title="'特殊'" @mouseover="colorOver(10)">
                  <el-image style="height: 50px" :src="require(`@/assets/img/analysis/pseudoColor/color10.png`)" fit="contain"></el-image>
                </div>
                <!-- <div class="pseudo-color" v-bind:title="''" @mouseover="colorOver(12)">
                <el-image style="height: 50px" :src="require(`@/assets/img/analysis/pseudoColor/color12.png`)" fit="contain"></el-image>
              </div>
              <div class="pseudo-color" v-bind:title="''" @mouseover="colorOver(13)">
                <el-image style="height: 50px" :src="require(`@/assets/img/analysis/pseudoColor/color13.png`)" fit="contain"></el-image>
              </div>
              <div class="pseudo-color" v-bind:title="''" @mouseover="colorOver(14)">
                <el-image style="height: 50px" :src="require(`@/assets/img/analysis/pseudoColor/color14.png`)" fit="contain"></el-image>
              </div>
              <div class="pseudo-color" v-bind:title="''" @mouseover="colorOver(16)">
                <el-image style="height: 50px" :src="require(`@/assets/img/analysis/pseudoColor/color16.png`)" fit="contain"></el-image>
              </div>
              <div class="pseudo-color" v-bind:title="''" @mouseover="colorOver(18)">
                <el-image style="height: 50px" :src="require(`@/assets/img/analysis/pseudoColor/color18.png`)" fit="contain"></el-image>
              </div>
              <div class="pseudo-color" v-bind:title="''" @mouseover="colorOver(19)">
                <el-image style="height: 50px" :src="require(`@/assets/img/analysis/pseudoColor/color19.png`)" fit="contain"></el-image>
              </div> -->
              </div>
              <div slot="reference" class="button-img" v-bind:title="'伪彩分析'" @click="functClick('pseudoColor')">
                <el-image
                  style="width: 100%; height: 100%"
                  :src="require(`@/assets/img/analysis/functionIcon/pseudo_color_def.png`)"
                  fit="contain"
                ></el-image>
              </div>
            </el-popover>
          </div>
          <div class="button-line">
            <div class="button-img" v-bind:title="'红外'" @click="functClick('infrared')">
              <el-image
                style="width: 100%; height: 100%"
                :src="require(`@/assets/img/analysis/functionIcon/${currentFunct === 'infrared' ? 'infrared_sel' : 'infrared_def'}.png`)"
                fit="contain"
              ></el-image>
            </div>
            <div class="button-img" v-bind:title="'可见光'" @click="functClick('visibleLight')" :style="lightStyle">
              <el-image
                style="width: 100%; height: 100%"
                :src="
                  require(`@/assets/img/analysis/functionIcon/light_${haveLight ? (currentFunct === 'visibleLight' ? 'sel' : 'def') : 'disable'}.png`)
                "
                fit="contain"
              ></el-image>
            </div>
          </div>
          <div class="button-line">
            <div class="button-img" v-bind:title="'彩色融合'" @click="functClick('fusion')" :style="lightStyle">
              <el-image
                style="width: 100%; height: 100%"
                :src="require(`@/assets/img/analysis/functionIcon/fusion_${haveLight ? (currentFunct === 'fusion' ? 'sel' : 'def') : 'disable'}.png`)"
                fit="contain"
              ></el-image>
            </div>
            <div class="button-img" v-bind:title="'画中画'" @click="functClick('PIP')" :style="lightStyle">
              <el-image
                style="width: 100%; height: 100%"
                :src="require(`@/assets/img/analysis/functionIcon/PIP_${haveLight ? (currentFunct === 'PIP' ? 'sel' : 'def') : 'disable'}.png`)"
                fit="contain"
              ></el-image>
            </div>
          </div>
          <div class="button-line">
            <!-- <div class="button-img" v-bind:title="'iMix'" @click="functClick('iMix')">
            <el-image
              style="width: 100%; height: 100%"
              :src="require(`@/assets/img/analysis/functionIcon/${currentFunct === 'iMix' ? 'imix_sel' : 'imix_def'}.png`)"
              fit="contain"
            ></el-image>
          </div> -->
            <div class="button-img" v-bind:title="'3D展示'" @click="functClick('3D')">
              <el-image
                style="width: 100%; height: 100%"
                :src="require(`@/assets/img/analysis/functionIcon/${currentFunct === '3D' ? '3D_sel' : '3D_def'}.png`)"
                fit="contain"
              ></el-image>
            </div>
          </div>
          <div class="button-line">
            <div class="button-img" v-bind:title="'以csv导出'" @click="functClick('csv')">
              <el-image style="width: 100%; height: 100%" :src="require(`@/assets/img/analysis/functionIcon/excel_def.png`)" fit="contain"></el-image>
            </div>
            <div class="button-img" v-bind:title="'以word导出测试报告'" @click="functClick('word')">
              <el-image style="width: 100%; height: 100%" :src="require(`@/assets/img/analysis/functionIcon/word_def.png`)" fit="contain"></el-image>
            </div>
          </div>
          <div class="button-line">
            <div class="button-img" v-bind:title="'以pdf导出测试报告'" @click="functClick('pdf')">
              <el-image style="width: 100%; height: 100%" :src="require(`@/assets/img/analysis/functionIcon/pdf_def.png`)" fit="contain"></el-image>
            </div>
            <!-- <div class="button-img" v-bind:title="'保存'" @click="functClick('save')">
          <el-image style="width: 100%; height: 100%" :src="require(`@/assets/img/analysis/functionIcon/save_def.png`)" fit="contain"></el-image>
        </div> -->
          </div>
        </div>
      </el-aside>

      <el-aside style="height: 100%; width: 20px; background: #f4f4f4"></el-aside>

      <el-main
        style="position: relative; overflow: hidden; background: white; box-shadow: 0px 2px 4px 0px rgba(168, 171, 179, 0.2); border-radius: 8px"
      >
        <!-- <div style="position: absolute; top: 0">
          <span class="back-span" @click="goBack">返回上一级</span>
          <input type="file" id="fileInput" name="file" @change="fileChange" />
        </div> -->
        <el-container style="height: 100%">
          <el-main style="padding: 10px 20px 0 10px">
            <div class="main-area">
              <div id="img-div">
                <div v-if="tempShowLocation === 'left'" class="main-left">
                  <div v-for="(item, index) in drawList" :key="index" class="left-temp" :style="fontStyle">
                    <span> {{ item.name || item.result.name }}</span>
                    <br />
                    <span v-if="item.type === 'point'"> {{ 'temp: ' + (item.avg || item.result.avg) }} </span>
                    <span v-else>
                      <span v-if="tempTypeCheckList.indexOf('avg') !== -1"> {{ 'avg: ' + (item.avg || item.result.avg) }}</span
                      ><br />
                      <span v-if="tempTypeCheckList.indexOf('max') !== -1"> {{ 'max: ' + (item.max || item.result.max) }}</span
                      ><br />
                      <span v-if="tempTypeCheckList.indexOf('min') !== -1"> {{ 'min: ' + (item.min || item.result.min) }}</span
                      ><br />
                    </span>
                  </div>
                </div>

                <div id="main-top">
                  <el-tag
                    v-for="(draw, index) in drawList"
                    :key="draw.name"
                    @close="roiDelete(index, draw.name)"
                    closable
                    type=""
                    style="margin-right: 5px"
                  >
                    {{ draw.name }}
                  </el-tag>
                </div>
                <div id="main-img">
                  <div class="content" :style="contentStyle">
                    <div id="relate-div">
                      <canvas
                        id="canvas"
                        @mousewheel.prevent="mouseWheel"
                        @mousemove="mouseMove"
                        @mouseout="mouseOut"
                        @mousedown="mouseDown"
                        @mouseup="mouseUp"
                        @contextmenu.prevent="contextMenu"
                        @click="mouseClick"
                      ></canvas>
                      <div v-if="showSelectedTemp && currentTemp" class="temp" :style="tempStyle">
                        {{ currentTemp + '℃' }}
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="showRightSlider" id="main-right">
                  <div id="refresh-btn" v-bind:title="'重置'" @click="reSetGraduatedValue">
                    <div id="btn-img"></div>
                  </div>
                  <div style="width: 22px; text-align: center">{{ graduatedValue[1] }}</div>

                  <div id="right-slider-outer" ref="rightSlider">
                    <div id="right-slider-top" :style="rightSliderTopStyle"></div>
                    <div id="right-slider-center" :style="rightSliderCenterStyle">
                      <div id="slider-btn-top" class="slider-btn" @mousedown="sliderMouseDown('top', $event)"></div>
                      <div id="slider-btn-bottom" class="slider-btn" @mousedown="sliderMouseDown('bottom', $event)"></div>
                    </div>
                    <div id="right-slider-bottom" :style="rightSliderBottomStyle"></div>
                  </div>
                  <div style="width: 22px; text-align: center">{{ graduatedValue[0] }}</div>
                </div>
                <div v-if="currentFunct === 'fusion' && isHaveImg" class="main-bottom">
                  <el-slider v-model="fusionScale" :min="0" :max="1" :step="0.1" @change="scaleChange"></el-slider>
                </div>
              </div>
            </div>
          </el-main>
        </el-container>
        <div id="pdfDom">
          <div class="pdf-title">测温分析报告</div>
          <div>
            <div class="s-title">1.测试环境</div>
            <table border="1" width="100%" style="border-collapse: collapse; border-spacing: 0">
              <tr width="100%" align="center">
                <td>测试仪器</td>
                <td>{{ params.sSerialName }}</td>
                <td>面阵大小</td>
                <td>{{ params.iwidth }} * {{ params.iheight }}</td>
              </tr>
              <tr align="center">
                <td>反射温度</td>
                <td>{{ pdfObj.fReflectTemp }}</td>
                <td>大气环境温度</td>
                <td>{{ pdfObj.fAirTemp }}</td>
              </tr>
              <tr align="center">
                <td>大气透过率</td>
                <td>{{ pdfObj.fHumidity }}</td>
                <td>发射率</td>
                <td>{{ pdfObj.fEmiss }}</td>
              </tr>
              <tr align="center">
                <td>距离</td>
                <td>{{ pdfObj.fDistance }}</td>
                <td>日期</td>
                <td>{{ timeNow }}</td>
              </tr>
            </table>
          </div>

          <div class="s-title">2.测试图像</div>
          <div class="canvas-div">
            <canvas id="pdf-ir"></canvas>
          </div>
          <div class="bottom-text">测试图像</div>
          <div class="canvas-div">
            <canvas id="pdf-light"></canvas>
          </div>
          <div class="bottom-text">可见光图像</div>

          <div>
            <div class="s-title">3.ROI测温数据</div>
            <table border="1" width="100%" style="border-collapse: collapse; border-spacing: 0">
              <tr width="100%" align="center">
                <td>编号</td>
                <td>最大值</td>
                <td>最小值</td>
                <td>平均值</td>
              </tr>
              <tr v-for="item in pdfObj.roiList" :key="item.name" align="center">
                <td>{{ item.name }}</td>
                <td>{{ item.max }}</td>
                <td>{{ item.min }}</td>
                <td>{{ item.avg }}</td>
              </tr>
            </table>
          </div>

          <div class="s-title">4.备注</div>
          <div>{{ pdfObj.remark }}</div>
        </div>
      </el-main>

      <el-aside style="height: 100%; width: 20px; background: #f4f4f4"></el-aside>

      <el-aside width="323px" class="info-aside">
        <el-tabs type="border-card" style="height: 100%">
          <el-tab-pane label="基础数据" style="height: 100%">
            <el-divider content-position="left">单位切换</el-divider>

            <el-form ref="imgInfo" label-width="90px" label-position="left" class="form-class">
              <el-form-item label="温度单位">
                <el-select v-model="imgInfo.temUnit" placeholder="温度单位">
                  <el-option v-for="item in temUnits" :label="item.name" :value="item.code" :key="item.code"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="距离单位">
                <el-select v-model="imgInfo.disUnit" placeholder="距离单位">
                  <el-option v-for="item in disUnits" :label="item.name" :value="item.code" :key="item.code"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <el-divider content-position="left">环境信息</el-divider>

            <el-form ref="imgInfo" label-width="180px" label-position="left" class="form-class">
              <el-form-item label="大气透过率(0.1~1)">
                <el-input-number v-model="imgInfo.envObj.fHumidity" :precision="2" :min="0.1" :max="1" :controls="false"></el-input-number>
              </el-form-item>
              <el-form-item label="发射率(0.1~1)">
                <el-input-number v-model="imgInfo.envObj.fEmiss" :precision="2" :min="0.1" :max="1" :controls="false"></el-input-number>
              </el-form-item>
              <el-form-item :label="`距离(${distRange[0] + '~' + distRange[1]})`">
                <el-input-number v-model="distance" :precision="2" :min="distRange[0]" :max="distRange[1]" :controls="false"></el-input-number>
              </el-form-item>
              <el-form-item :label="`环境温度(${tempRange[0] + '~' + tempRange[1]})`">
                <el-input-number v-model="airTemp" :precision="2" :min="tempRange[0]" :max="tempRange[1]" :controls="false"></el-input-number>
              </el-form-item>
              <el-form-item :label="`反射温度(${tempRange[0] + '~' + tempRange[1]})`">
                <el-input-number v-model="reflectTemp" :precision="2" :min="tempRange[0]" :max="tempRange[1]" :controls="false"></el-input-number>
              </el-form-item>
            </el-form>

            <div id="param-btn">
              <el-button type="primary" size="mini" @click="refreshParam">重置</el-button>
              <el-button type="primary" size="mini" @click="setParam">设置</el-button>
            </div>

            <el-divider content-position="left">图像信息</el-divider>

            <el-descriptions
              :column="1"
              :colon="false"
              labelClassName="detail-label"
              :labelStyle="{ 'margin-left': '15px !important' }"
              style="word-break: break-all"
            >
              <el-descriptions-item label="产品系列">{{ imgInfo.deviceObj.suit }}</el-descriptions-item>
              <el-descriptions-item label="设备型号">{{ imgInfo.deviceObj.model }}</el-descriptions-item>
              <el-descriptions-item label="设备名称">{{ imgInfo.deviceObj.name }}</el-descriptions-item>
              <el-descriptions-item label="图片名称">{{ imgInfo.imgObj.name }}</el-descriptions-item>
              <el-descriptions-item label="图像分辨率">{{ imgInfo.imgObj.dpi }}</el-descriptions-item>
              <el-descriptions-item label="创建日期">{{ imgInfo.imgObj.uploadTime }}</el-descriptions-item>
              <el-descriptions-item label="大小">{{ imgInfo.imgObj.fileSize }}</el-descriptions-item>
            </el-descriptions>
          </el-tab-pane>
          <el-tab-pane label="数值分析" style="height: 96%">
            <el-divider content-position="left">测温曲线</el-divider>
            <el-form label-width="80px">
              <el-form-item label="测温直线">
                <el-select v-model="lineName" @change="lineChange" placeholder="选择测温直线">
                  <div v-for="item in drawList" :key="item.name">
                    <el-option v-if="item.type === 'line'" :value="item.name"></el-option>
                  </div>
                </el-select>
              </el-form-item>
            </el-form>
            <div id="line-chart" class="aside-chart"></div>

            <el-divider content-position="left">测温直方图</el-divider>
            <el-form label-width="80px">
              <el-form-item label="测温区域">
                <el-select v-model="areaName" @change="areaChange" placeholder="选择测温区域">
                  <div v-for="item in drawList" :key="item.name">
                    <el-option v-if="item.type !== 'line' && item.type !== 'point'" :value="item.name"></el-option>
                  </div>
                </el-select>
              </el-form-item>
            </el-form>
            <div id="area-chart" class="aside-chart"></div>
          </el-tab-pane>
        </el-tabs>
      </el-aside>
      <el-dialog title="3D展示" :visible.sync="dialogVisible" @closed="dialogClose">
        <div id="threeD-div"></div>
        <div slot="footer">
          <el-button type="primary" @click="dialogVisible = false">关 闭</el-button>
        </div>
      </el-dialog>
    </el-container>
  </el-container>
</template>

<script>
// import { debounce } from '@/utils'
import * as echarts from 'echarts'
import 'echarts-gl'
import axios from 'axios'
import { getParams, getPointTemp, getLineTemp, getRctTemp, getCircleTemp, getPolyTemp } from '@/utils/temperature.js'
import { exportWord } from '@/utils/exportWord.js'
import { exportPdf } from '@/utils/exportPdf.js'
import { timeFormat, findStr } from '@/utils'

var jpeg = require('jpeg-js')
var cv = require('opencv.js')

export default {
  name: 'Analysis',
  data() {
    return {
      irList: [],
      dcList: [],
      irgList: [],
      isHaveImg: false,
      currentFunct: '',
      temUnits: [
        { code: 1, name: '摄氏度' },
        { code: 2, name: '华氏度' },
        { code: 3, name: '开尔文' }
      ],
      disUnits: [
        { code: 1, name: '米' },
        { code: 2, name: '英尺' }
      ],
      imgInfo: {
        temUnit: 1,
        disUnit: 1,
        envObj: {
          fHumidity: undefined,
          fEmiss: undefined,
          fDistance: [],
          fAirTemp: [],
          fReflectTemp: []
        },
        deviceObj: {
          suit: '',
          model: '',
          name: ''
        },
        imgObj: {
          name: '',
          dpi: '',
          uploadTime: ''
        }
      },
      params: { sSerialName: '' }, // 参数信息
      mLightMat: undefined, // 可见光
      mIRMat: undefined, // 红外
      midMat: undefined, // 伪彩
      canvasMat: undefined, // 当前底图
      newMat: undefined, // 最新画布数据
      pTempBufferDst: [], // 16进制的温度数据
      currentTemp: undefined, // 当前鼠标处温度
      offsetX: 0,
      offsetY: 0,
      // debounceFn: debounce(this.getMouseTemp, 200), // 防抖
      showSelectedTemp: false, // 是否显示选择温度
      fontDialogVisible: false,
      fontColor: '#0000ff',
      fontSize: 15,
      tempTypeCheckList: ['max', 'min', 'avg'],
      tempShowLocation: 'nrml',
      pointCount: 1,
      linCount: 1,
      rctCount: 1,
      circleCount: 1,
      polyCount: 1,
      startFlag: false, // 是否开始绘制
      drawList: [], // 图像列表
      polygonXYList: [], // 多边形顶点坐标，{x:1,y:1}
      sebanNewList: [], // 伪彩
      pseudoColorNum: 0, // 选中的伪彩号
      fusionScale: 0.0, // 彩色融合程度
      pipMat: undefined, // 画中画
      timeNow: '',
      threeDChart: undefined, // 3Dchart实例
      lineName: '',
      areaName: '',
      lineChart: undefined, // 直线图表
      areaChart: undefined, // 区域图表
      haveLight: true, // 是否有可见光
      pdfObj: {}, // pdf信息
      backUpEnv: {}, // 备份环境参数
      graduatedColors: [], // 渐变色
      graduatedValue: [], // 渐变值
      graduatedRange: [], // 渐变滑块范围
      triSize: 4, // 最大最小坐标标记的大小
      zoom: 1, // 放大倍数
      showRightSlider: false,
      sliderTopHeight: 0, // 右侧滑块上下高度
      sliderBottomHeight: 0,
      dragable: false, // 是否可拖动
      clientY: 0, // 滑块初始clientY
      currentSliderBtn: '', // 当前操作滑块按钮
      timeOutId: undefined,
      dialogVisible: false,
      minWidth: '100%', // content的最小宽度 高度
      minHeight: '100%'
    }
  },
  computed: {
    contentStyle() {
      return `min-width: ${this.minWidth}; min-height: ${this.minHeight};`
    },
    lightStyle() {
      return !this.haveLight ? 'pointer-events: none;' : ''
    },
    tempStyle() {
      return `left: ${this.offsetX + 10}px; top: ${this.offsetY}px; color: ${this.fontColor}; font-size: ${this.fontSize}px;`
    },
    fontStyle() {
      return `color: ${this.fontColor}; font-size: ${this.fontSize}px;`
    },
    // 距离范围
    distRange() {
      if (this.imgInfo.disUnit === 2) {
        // 英尺
        return [0.25 * 3.28, 20 * 3.28]
      } else {
        // 米
        return [0.25, 20]
      }
    },
    // 距离
    distance: {
      get() {
        if (this.imgInfo.disUnit === 2) {
          // 英尺
          return this.imgInfo.envObj.fDistance[1]
        } else if (this.imgInfo.disUnit === 1) {
          // 米
          return this.imgInfo.envObj.fDistance[0]
        } else {
          return ''
        }
      },
      set(val) {
        let meter = undefined
        let feet = undefined

        if (this.imgInfo.disUnit === 2) {
          // 英尺
          feet = val
          meter = feet / 3.28
        } else if (this.imgInfo.disUnit === 1) {
          // 米
          meter = val
          feet = meter * 3.28
        }

        this.imgInfo.envObj.fDistance = [meter, feet]
      }
    },
    // 距离单位
    disUnitName() {
      if (this.imgInfo.disUnit === 2) {
        // 英尺
        return 'In'
      } else if (this.imgInfo.disUnit === 1) {
        // 米
        return 'M'
      } else {
        return ''
      }
    },
    // 温度范围
    tempRange() {
      if (this.imgInfo.temUnit === 2) {
        // 华氏度
        return [-10 * 1.8 + 32, 50 * 1.8 + 32]
      } else if (this.imgInfo.temUnit === 3) {
        // 开尔文
        return [263.15, 323.15]
      } else {
        // 摄氏度
        return [-10, 50]
      }
    },
    // 温度单位
    temUnit() {
      return this.imgInfo.temUnit
    },
    // 环境温度
    airTemp: {
      get() {
        if (this.imgInfo.temUnit === 3) {
          // 开尔文
          return this.imgInfo.envObj.fAirTemp[2]
        } else if (this.imgInfo.temUnit === 2) {
          // 华氏度
          return this.imgInfo.envObj.fAirTemp[1]
        } else if (this.imgInfo.temUnit === 1) {
          // 摄氏度
          return this.imgInfo.envObj.fAirTemp[0]
        } else {
          return ''
        }
      },
      set(val) {
        let tempK = undefined
        let tempF = undefined
        let tempD = undefined

        if (this.imgInfo.temUnit === 3) {
          // 开尔文
          tempK = val
          tempD = tempK - 273.15
          tempF = tempD * 1.8 + 32
        } else if (this.imgInfo.temUnit === 2) {
          // 华氏度
          tempF = val
          tempD = (tempF - 32) / 1.8
          tempK = tempD + 273.15
        } else if (this.imgInfo.temUnit === 1) {
          // 摄氏度
          tempD = val
          tempK = tempD + 273.15
          tempF = tempD * 1.8 + 32
        }

        this.imgInfo.envObj.fAirTemp = [tempD, tempF, tempK]
      }
    },
    // 反射温度
    reflectTemp: {
      get() {
        if (this.imgInfo.temUnit === 3) {
          // 开尔文
          return this.imgInfo.envObj.fReflectTemp[2]
        } else if (this.imgInfo.temUnit === 2) {
          // 华氏度
          return this.imgInfo.envObj.fReflectTemp[1]
        } else if (this.imgInfo.temUnit === 1) {
          // 摄氏度
          return this.imgInfo.envObj.fReflectTemp[0]
        } else {
          return ''
        }
      },
      set(val) {
        let tempK = undefined
        let tempF = undefined
        let tempD = undefined

        if (this.imgInfo.temUnit === 3) {
          // 开尔文
          tempK = val
          tempD = tempK - 273.15
          tempF = tempD * 1.8 + 32
        } else if (this.imgInfo.temUnit === 2) {
          // 华氏度
          tempF = val
          tempD = (tempF - 32) / 1.8
          tempK = tempD + 273.15
        } else if (this.imgInfo.temUnit === 1) {
          // 摄氏度
          tempD = val
          tempK = tempD + 273.15
          tempF = tempD * 1.8 + 32
        }

        this.imgInfo.envObj.fReflectTemp = [tempD, tempF, tempK]
      }
    },
    // 温度单位
    tempUnitName() {
      if (this.imgInfo.temUnit === 3) {
        // 开尔文
        return 'K'
      } else if (this.imgInfo.temUnit === 2) {
        // 华氏度
        return 'F'
      } else if (this.imgInfo.temUnit === 1) {
        // 摄氏度
        return '℃'
      } else {
        return ''
      }
    },
    rightSliderTopStyle() {
      const rgb = this.graduatedColors[0]
      return `height: ${this.sliderTopHeight}%; background: rgb(${rgb.r}, ${rgb.g}, ${rgb.b});`
    },
    rightSliderCenterStyle() {
      const colorList = []
      this.graduatedColors.forEach((rgb) => {
        colorList.push(`rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`)
      })
      return `height: ${100 - this.sliderTopHeight - this.sliderBottomHeight}%; background: linear-gradient(${colorList.toString()});`
    },
    rightSliderBottomStyle() {
      const rgb = this.graduatedColors[this.graduatedColors.length - 1]
      return `height:  ${this.sliderBottomHeight}%; background: rgb(${rgb.r}, ${rgb.g}, ${rgb.b}); `
    }
  },
  watch: {
    temUnit(val) {
      // 修改测温区域显示的温度
      const getTemp = this.getTempFunc(val)
      this.drawList.forEach((draw) => {
        const { max, min, avg } = draw.resultBak
        if (draw.type === 'point') {
          draw.avg = getTemp(avg)
          draw.max = getTemp(max)
          draw.min = getTemp(min)
        } else {
          draw.result.avg = getTemp(avg)
          draw.result.max = getTemp(max)
          draw.result.min = getTemp(min)
        }
      })

      this.styleChange()

      // 重绘图表
      if (this.lineName) {
        this.lineChange(this.lineName)
      }
      if (this.areaName) {
        this.areaChange(this.areaName)
      }
    }
  },
  mounted() {
    const { isIrg, irgUrl, irUrl, dcUrl, deviceObj, imgObj, isPX1, params } = JSON.parse(sessionStorage.analysis || '{}')
    // 设备信息
    this.imgInfo.deviceObj = deviceObj || {}
    // 图像信息
    this.imgInfo.imgObj = imgObj || {}

    if (isIrg && irgUrl) {
      // 获取irgList
      this.getImgData(irgUrl, 'irg')
    } else if (!isIrg && irUrl && dcUrl) {
      // 获取dcList irList
      const p1 = new Promise((resolve) => {
        this.getImgData(irUrl, 'ir', resolve)
      })
      const p2 = new Promise((resolve) => {
        this.getImgData(dcUrl, 'dc', resolve)
      })
      Promise.all([p1, p2]).then(() => {
        // 整合图像信息
        this.integrateImgInfo(getParams({ isGW: true, irU8Array: this.irList, dcU8Array: this.dcList }))
      })
    } else if (isPX1) {
      // 整合图像信息
      this.integrateImgInfo(params)
    } else {
      this.goBack()
    }
  },
  methods: {
    // 关闭3D的回调
    dialogClose() {
      this.threeDChart.dispose()
      this.currentFunct = ''
    },
    // 滑块鼠标按下
    sliderMouseDown(type, e) {
      this.currentSliderBtn = type
      this.dragable = true
      this.clientY = e.clientY
    },
    // 鼠标移动
    sliderMouseMove(e) {
      if (this.dragable) {
        // 获取父级高度
        const parentHeight = this.$refs.rightSlider.clientHeight
        // 计算拖动的百分比和温度值
        const percent = ((e.clientY - this.clientY) * 100) / parentHeight
        if (this.currentSliderBtn === 'top') {
          this.sliderTopHeight =
            this.sliderTopHeight + percent >= 97 - this.sliderBottomHeight ? 97 - this.sliderBottomHeight : this.sliderTopHeight + percent
          this.sliderTopHeight = this.sliderTopHeight < 0 ? 0 : this.sliderTopHeight

          if (this.sliderTopHeight) {
            this.graduatedValue[1] = parseFloat(
              (this.graduatedRange[1] - ((this.graduatedRange[1] - this.graduatedRange[0]) * this.sliderTopHeight) / 100).toFixed(1)
            )
          } else {
            this.graduatedValue[1] = this.graduatedRange[1]
          }
        } else if (this.currentSliderBtn === 'bottom') {
          this.sliderBottomHeight =
            this.sliderBottomHeight - percent >= 97 - this.sliderTopHeight ? 97 - this.sliderTopHeight : this.sliderBottomHeight - percent
          this.sliderBottomHeight = this.sliderBottomHeight < 0 ? 0 : this.sliderBottomHeight

          if (this.sliderBottomHeight) {
            this.graduatedValue[0] = parseFloat(
              (this.graduatedRange[0] + ((this.graduatedRange[1] - this.graduatedRange[0]) * this.sliderBottomHeight) / 100).toFixed(1)
            )
          } else {
            this.graduatedValue[0] = this.graduatedRange[0]
          }
        }
        this.clientY = e.clientY

        if (this.timeOutId) {
          clearTimeout(this.timeOutId)
        }
        this.timeOutId = setTimeout(() => {
          this.graduatedValueChange()
        }, 100)
      }
    },
    // 鼠标抬起
    moveUp() {
      this.dragable = false
    },
    // 获取不同单位的温度算法
    getTempFunc(temUnit) {
      // 摄氏度
      let getTemp = (temp) => {
        return this.amend(temp).toFixed(2)
      }
      if (temUnit === 2) {
        // 华氏度
        getTemp = (temp) => {
          return (this.amend(temp) * 1.8 + 32).toFixed(2)
        }
      } else if (temUnit === 3) {
        // 开尔文
        getTemp = (temp) => {
          return (this.amend(temp) + 273.15).toFixed(2)
        }
      }

      return getTemp
    },
    // 重置渐变值
    reSetGraduatedValue() {
      this.sliderTopHeight = 0
      this.sliderBottomHeight = 0
      this.graduatedValue = [this.graduatedRange[0], this.graduatedRange[1]]
      this.changeTempWidth()
    },
    // 改变渐变值
    graduatedValueChange() {
      this.changeTempWidth()

      if (this.currentFunct === 'fusion') {
        // 融合
        this.scaleChange()
      } else if (this.currentFunct === 'PIP') {
        // 画中画
        this.getPIP()
      } else if (this.currentFunct === 'iMix') {
        // iMix
        this.getImix()
      }
    },
    // 改变温宽
    changeTempWidth() {
      const fMax = this.graduatedValue[1]
      const fMin = this.graduatedValue[0]
      const fFrameMax = this.graduatedRange[1]
      const fFrameMin = this.graduatedRange[0]
      const mat = cv.matFromArray(this.params.iheight, this.params.iwidth, cv.CV_8UC1, this.params.mIRBuffer)

      for (let j = 0; j < this.params.iheight; j++) {
        for (let i = 0; i < this.params.iwidth; i++) {
          const fGh = (255 * (fMax - fFrameMin)) / (fFrameMax - fFrameMin)
          const fGl = (255 * (fMin - fFrameMin)) / (fFrameMax - fFrameMin)
          let temp = (255 * (mat.ucharPtr(j, i)[0] * 1.0 - fGl)) / (fGh - fGl)
          if (temp > 255) {
            temp = 255
          } else if (temp < 0) {
            temp = 0
          }
          mat.ucharPtr(j, i)[0] = temp
        }
      }

      cv.cvtColor(mat, this.mIRMat, cv.COLOR_GRAY2BGR)
      this.addMapcolor(this.pseudoColorNum)
      this.canvasMat = this.midMat.clone()
      this.styleChange()
    },
    // 设置环境参数
    setParam() {
      if (
        !this.imgInfo.envObj.fDistance[0] ||
        !this.imgInfo.envObj.fReflectTemp[2] ||
        !this.imgInfo.envObj.fAirTemp[2] ||
        !this.imgInfo.envObj.fHumidity ||
        !this.imgInfo.envObj.fEmiss
      ) {
        this.$message.error('环境信息不能为空')
        return
      }

      const getTemp = this.getTempFunc(this.imgInfo.temUnit)
      this.drawList.forEach((draw) => {
        const { max, min, avg } = draw.resultBak
        if (draw.type === 'point') {
          draw.avg = getTemp(avg)
          draw.max = getTemp(max)
          draw.min = getTemp(min)
        } else {
          draw.result.avg = getTemp(avg)
          draw.result.max = getTemp(max)
          draw.result.min = getTemp(min)
        }
      })

      this.styleChange()

      // 重绘图表
      if (this.lineName) {
        this.lineChange(this.lineName)
      }
      if (this.areaName) {
        this.areaChange(this.areaName)
      }
    },
    // 重置环境参数
    refreshParam() {
      this.imgInfo.envObj = { ...this.backUpEnv }
    },
    // 温度修正方法
    amend(temp) {
      const oldTmep = temp
      const { k0, b0, k1, b1 } = this.params // 距离修正系数
      // 旧值
      const distance = this.backUpEnv.fDistance[0] // 距离 米
      const fReflectTemp = this.backUpEnv.fReflectTemp[2] // 反射温度 开尔文
      const fAirTemp = this.backUpEnv.fAirTemp[2] // 大气温度 开尔文
      const fHumidity = this.backUpEnv.fHumidity // 大气透过率
      const fEmiss = this.backUpEnv.fEmiss // 发射率
      // 新值
      const distanceNew = this.imgInfo.envObj.fDistance[0] // 距离 米
      const fReflectTempNew = this.imgInfo.envObj.fReflectTemp[2] // 反射温度 开尔文
      const fAirTempNew = this.imgInfo.envObj.fAirTemp[2] // 大气温度 开尔文
      const fHumidityNew = this.imgInfo.envObj.fHumidity // 大气透过率
      const fEmissNew = this.imgInfo.envObj.fEmiss // 发射率

      temp = parseFloat(temp)
      temp += 273.15
      // 1st, 经过距离修正前的温度为
      temp = temp * (k0 * distance + b0) + k1 * distance + b1
      // 2nd, 环境变量修正前的温度
      temp = Math.sqrt(
        fHumidity * fEmiss * (temp * temp) + fHumidity * (1 - fEmiss) * (fReflectTemp * fReflectTemp) + (1 - fHumidity) * (fAirTemp * fAirTemp)
      )
      // 3rd, 根据新设置的环境变量参数, 计算环境变量改变后的温度
      temp =
        ((temp * temp) / fHumidityNew -
          (1 - fEmissNew) * (fReflectTempNew * fReflectTempNew) -
          (1 / fHumidityNew - 1) * (fAirTempNew * fAirTempNew)) /
        fEmissNew

      if (temp < 0.00001) {
        this.$message.error('环境参数设置不合理')
        this.refreshParam()
        return parseFloat(oldTmep)
      }

      temp = Math.sqrt(temp)
      // 4th, 根据新设置的距离 对修正后的值进行距离补偿得到
      temp = temp / (k0 * distanceNew + b0) - (k1 * distanceNew + b1) / (k0 * distanceNew + b0)
      return temp - 273.15
    },
    // 删除测温区域
    roiDelete(index, name) {
      this.drawList.splice(index, 1)
      this.styleChange()

      // 销毁图表
      if (this.lineName === name) {
        this.lineChart.dispose()
        this.lineName = ''
      } else if (this.areaName === name) {
        this.areaChart.dispose()
        this.areaName = ''
      }
    },
    // 整合图像信息
    integrateImgInfo(params) {
      // 环境信息
      const { fHumidity, fEmiss, fDistance, fAirTemp, fReflectTemp, maxTemp, minTemp } = params
      const envParam = {
        fHumidity,
        fEmiss,
        fDistance: [fDistance, fDistance * 3.28], // 米 英尺
        fAirTemp: [fAirTemp - 273.15, (fReflectTemp - 273.15) * 1.8 + 32, fAirTemp], // 摄氏度 华氏度 开尔文
        fReflectTemp: [fReflectTemp - 273.15, (fReflectTemp - 273.15) * 1.8 + 32, fReflectTemp]
      }
      this.imgInfo.envObj = envParam
      this.backUpEnv = { ...envParam }
      this.params = params

      // 设置最大最小温度
      this.graduatedValue = [parseFloat(minTemp), parseFloat(maxTemp)]
      this.graduatedRange = [parseFloat(minTemp), parseFloat(maxTemp)]

      // 获取Mat
      this.getMat()
    },
    // 返回
    goBack() {
      this.$router.go(-1)
    },
    // 获取Mat
    getMat() {
      const { iwidth, iheight, pTempBufferDst, mIRBuffer, lightBuffer, gwType, irHeight, irWidth } = this.params

      // 红外
      // 灰度图
      let img = undefined
      if (gwType === 'PX1') {
        img = cv.matFromArray(irHeight, irWidth, cv.CV_8UC1, mIRBuffer)
      } else {
        img = cv.matFromArray(iheight, iwidth, cv.CV_8UC1, mIRBuffer)
      }
      this.mIRMat = img.clone()
      cv.cvtColor(this.mIRMat, this.mIRMat, cv.COLOR_GRAY2BGR)
      if (gwType === 'PX1') {
        cv.resize(this.mIRMat, this.mIRMat, new cv.Size(iwidth, iheight))
      }

      this.canvasMat = this.mIRMat.clone()
      cv.imshow('canvas', this.canvasMat)

      // 可见光
      // 解码
      if (lightBuffer && lightBuffer.length > 0) {
        this.mLightMat = cv.matFromImageData(jpeg.decode(lightBuffer))
        if (gwType === 'PX1') {
          cv.resize(this.mLightMat, this.mLightMat, new cv.Size(iwidth, iheight))
        }
      } else {
        this.haveLight = false
      }

      // 温度
      this.pTempBufferDst = pTempBufferDst
      this.isHaveImg = true

      // 绘制之前的测温区域
      const roiTypeAndInfo = this.params.roiTypeAndInfo || {}
      let infoList = []
      if (Object.keys(roiTypeAndInfo).length > 0) {
        for (const type in roiTypeAndInfo) {
          switch (type) {
            // 点
            case 'point':
              infoList = roiTypeAndInfo[type] || []
              infoList.forEach((info) => {
                const { x, y } = info
                // 获取温度
                const temp = getPointTemp(x, y, this.pTempBufferDst, this.params.cProType, iwidth).avg
                // 获取文字描述
                const name = 'P' + this.pointCount
                // 计数器——点
                this.pointCount++

                this.drawList.push({
                  type: 'point',
                  name,
                  max: temp,
                  min: temp,
                  avg: temp,
                  newX: x,
                  newY: y,
                  resultBak: { max: temp, min: temp, avg: temp }
                })
              })

              break
            // 线
            case 'line':
              infoList = roiTypeAndInfo[type] || []
              infoList.forEach((info) => {
                const { startX, startY, endX, endY } = info

                const result = getLineTemp(startX, startY, endX, endY, this.pTempBufferDst, this.params.cProType, iwidth, iheight)
                result.name = 'L' + this.linCount

                this.linCount++
                this.drawList.push({
                  name: result.name,
                  type: 'line',
                  oldX: startX,
                  oldY: startY,
                  newX: endX,
                  newY: endY,
                  result,
                  resultBak: { max: result.max, min: result.min, avg: result.avg }
                })
              })

              break

            // 矩形
            case 'rectangle':
              infoList = roiTypeAndInfo[type] || []
              infoList.forEach((info) => {
                const { startX, startY, endX, endY } = info

                const result = getRctTemp(startX, startY, endX, endY, this.pTempBufferDst, this.params.cProType, iwidth, iheight)
                result.name = 'R' + this.rctCount

                this.rctCount++
                this.drawList.push({
                  name: result.name,
                  type: 'rectangle',
                  oldX: startX,
                  oldY: startY,
                  newX: endX,
                  newY: endY,
                  result,
                  resultBak: { max: result.max, min: result.min, avg: result.avg }
                })
              })

              break

            // 圆
            case 'circle':
              infoList = roiTypeAndInfo[type] || []
              infoList.forEach((info) => {
                const { x, y, r } = info

                const result = getCircleTemp(x, y, r, this.pTempBufferDst, this.params.cProType, iwidth)
                result.name = 'C' + this.circleCount

                this.circleCount++
                this.drawList.push({
                  name: result.name,
                  type: 'circle',
                  oldX: x,
                  oldY: y,
                  newX: x,
                  newY: y + r,
                  result,
                  resultBak: { max: result.max, min: result.min, avg: result.avg }
                })
              })

              break

            // 多边形
            case 'polygon':
              infoList = roiTypeAndInfo[type] || []
              infoList.forEach((info) => {
                const result = getPolyTemp(info, this.pTempBufferDst, this.params.cProType, iwidth, iheight)
                result.name = 'G' + this.polyCount

                this.polyCount++
                this.drawList.push({
                  name: result.name,
                  type: 'polygon',
                  polyXYList: JSON.parse(JSON.stringify(info)),
                  result,
                  resultBak: { max: result.max, min: result.min, avg: result.avg }
                })
              })

              break

            default:
              break
          }
        }
      }

      // 获取伪彩
      axios
        .get(`/${this.params.gwType === 'PX1' ? 'ColorMapPX1' : 'seban_new_list'}.dat`, {
          responseType: 'blob'
        })
        .then((res) => {
          const blob = new Blob([res.data])
          const reader = new FileReader()
          reader.readAsArrayBuffer(blob)
          reader.onload = (e) => {
            const array = new Uint8Array(e.target.result)
            this.sebanNewList = this.params.gwType === 'PX1' ? array.slice(20 * 1024) : array

            // 叠加之前保存的伪彩
            if (this.params.colorIndex) {
              let index = this.params.colorIndex
              if (this.params.cProType === 'CAAC') {
                switch (this.params.colorIndex) {
                  case 2:
                    index = 4
                    break
                  case 3:
                    index = 5
                    break
                  case 4:
                    index = 2
                    break
                  case 5:
                    index = 3
                    break
                  case 6:
                    index = 8
                    break
                  case 0:
                    index = 0
                    break
                  case 1:
                    index = 1
                    break
                  default:
                    break
                }
              }
              this.pseudoColorNum = index
              this.addMapcolor(index)
              this.canvasMat = this.midMat.clone()
            }

            // 切换之前的模式
            let funct = ''
            switch (this.params.imgModel) {
              case 1:
                // 融合
                funct = 'fusion'
                break
              case 2:
                // 画中画
                funct = 'PIP'
                break
              case 3:
                // iMix
                funct = 'iMix'
                break
              case 4:
                // 可见光
                funct = 'visibleLight'
                break

              default:
                break
            }
            if (funct) {
              this.functClick(funct)
            }

            this.styleChange()
          }
        })
        .catch((err) => {
          console.log('获取伪彩失败 => ', err)
        })
    },
    // 更改测温直线
    lineChange(e) {
      const lineItem =
        this.drawList.find((draw) => {
          return draw.name === e
        }) || {}

      const xData = []
      const yData = []
      const tempList = lineItem.result.tempList || []
      const getTemp = this.getTempFunc(this.imgInfo.temUnit)

      for (let i = 0; i < tempList.length; i++) {
        xData.push(i)
        yData.push(getTemp(tempList[i]))
      }

      if (!this.lineChart || this.lineChart.isDisposed()) {
        this.lineChart = echarts.init(document.getElementById('line-chart'))
      }
      const option = {
        tooltip: {},
        toolbox: {
          feature: {
            dataZoom: {
              yAxisIndex: 'none',
              emphasis: {
                iconStyle: {
                  textPosition: 'top'
                }
              }
            }
          },
          right: '8%',
          top: '5%'
        },
        grid: {
          left: '3%',
          right: '3%',
          bottom: '8%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: xData,
          name: '位置',
          nameLocation: 'center',
          nameGap: 25,
          boundaryGap: false // 以刻度作为横坐标
        },
        yAxis: {
          type: 'value',
          name: this.imgInfo.temUnit === 1 ? '温度(℃)' : this.imgInfo.temUnit === 2 ? '温度(F)' : '温度(K)',
          scale: true
        },
        series: [
          {
            data: yData,
            type: 'line',
            smooth: true
          }
        ]
      }
      this.lineChart.setOption(option)
    },
    // 更改测温区域
    areaChange(e) {
      const lineItem =
        this.drawList.find((draw) => {
          return draw.name === e
        }) || {}

      const tempList = lineItem.result.tempList || []

      const tempAndNum = {}
      tempList.forEach((item) => {
        const temp = item.toFixed(2)
        if (tempAndNum[temp]) {
          tempAndNum[temp]++
        } else {
          tempAndNum[temp] = 1
        }
      })

      const xData = []
      const yData = []
      const getTemp = this.getTempFunc(this.imgInfo.temUnit)

      for (const key in tempAndNum) {
        xData.push(getTemp(key))
        yData.push(((tempAndNum[key] / tempList.length) * 100).toFixed(2))
      }

      for (let i = 0; i < xData.length - 1; i++) {
        for (let j = 0; j < xData.length - 1 - i; j++) {
          if (xData[j] > xData[j + 1]) {
            const thridX = xData[j + 1]
            xData[j + 1] = xData[j]
            xData[j] = thridX

            const thridY = yData[j + 1]
            yData[j + 1] = yData[j]
            yData[j] = thridY
          }
        }
      }

      if (!this.areaChart || this.areaChart.isDisposed()) {
        this.areaChart = echarts.init(document.getElementById('area-chart'))
      }
      const option = {
        tooltip: {},
        grid: {
          left: '3.5%',
          right: '3%',
          bottom: '8%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: xData,
            name: this.imgInfo.temUnit === 1 ? '温度(℃)' : this.imgInfo.temUnit === 2 ? '温度(F)' : '温度(K)',
            nameLocation: 'center',
            nameGap: 25
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '百分比(%)'
          }
        ],
        series: [
          {
            type: 'bar',
            data: yData
          }
        ]
      }
      this.areaChart.setOption(option)
    },
    // imix
    getImix() {
      if (!this.isHaveImg) return

      console.time('imix')
      const light = this.mLightMat.clone()
      if (this.addMapcolor(this.pseudoColorNum)) {
        // 叠伪彩
        let ground1 = 0.0
        let ground2 = 0.0
        let ground3 = 0.0
        let detail1 = 0.0
        let detail2 = 0.0
        let detail3 = 0.0

        for (let j = 0; j < this.params.iheight; j++) {
          //x
          for (let i = 0; i < this.params.iwidth; i++) {
            //y
            if (i == 0 && j == 0) {
              ground1 =
                (1.0 / 9) *
                (light.ucharPtr(j, i)[0] +
                  light.ucharPtr(0, 0)[0] +
                  light.ucharPtr(0, i)[0] +
                  light.ucharPtr(0, i + 1)[0] +
                  light.ucharPtr(j, 0)[0] +
                  light.ucharPtr(j, i + 1)[0] +
                  light.ucharPtr(j + 1, i)[0] +
                  light.ucharPtr(j + 1, i)[0] +
                  light.ucharPtr(j + 1, i + 1)[0])
              ground2 =
                (1.0 / 9) *
                (light.ucharPtr(j, i)[1] +
                  light.ucharPtr(0, 0)[1] +
                  light.ucharPtr(0, i)[1] +
                  light.ucharPtr(0, i + 1)[1] +
                  light.ucharPtr(j, 0)[1] +
                  light.ucharPtr(j, i + 1)[1] +
                  light.ucharPtr(j + 1, i)[1] +
                  light.ucharPtr(j + 1, i)[1] +
                  light.ucharPtr(j + 1, i + 1)[1])
              ground3 =
                (1.0 / 9) *
                (light.ucharPtr(j, i)[2] +
                  light.ucharPtr(0, 0)[2] +
                  light.ucharPtr(0, i)[2] +
                  light.ucharPtr(0, i + 1)[2] +
                  light.ucharPtr(j, 0)[2] +
                  light.ucharPtr(j, i + 1)[2] +
                  light.ucharPtr(j + 1, i)[2] +
                  light.ucharPtr(j + 1, i)[2] +
                  light.ucharPtr(j + 1, i + 1)[2])
            }
            if (i == 0 && j != 0) {
              ground1 =
                (1.0 / 9) *
                (light.ucharPtr(j, i)[0] +
                  light.ucharPtr(j - 1, 0)[0] +
                  light.ucharPtr(j - 1, i)[0] +
                  light.ucharPtr(j - 1, i + 1)[0] +
                  light.ucharPtr(j, 0)[0] +
                  light.ucharPtr(j, i + 1)[0] +
                  light.ucharPtr(j + 1, 0)[0] +
                  light.ucharPtr(j + 1, i)[0] +
                  light.ucharPtr(j + 1, i + 1)[0])
              ground2 =
                (1.0 / 9) *
                (light.ucharPtr(j, i)[1] +
                  light.ucharPtr(j - 1, 0)[1] +
                  light.ucharPtr(j - 1, i)[1] +
                  light.ucharPtr(j - 1, i + 1)[1] +
                  light.ucharPtr(j, 0)[1] +
                  light.ucharPtr(j, i + 1)[1] +
                  light.ucharPtr(j + 1, 0)[1] +
                  light.ucharPtr(j + 1, i)[1] +
                  light.ucharPtr(j + 1, i + 1)[1])
              ground3 =
                (1.0 / 9) *
                (light.ucharPtr(j, i)[2] +
                  light.ucharPtr(j - 1, 0)[2] +
                  light.ucharPtr(j - 1, i)[2] +
                  light.ucharPtr(j - 1, i + 1)[2] +
                  light.ucharPtr(j, 0)[2] +
                  light.ucharPtr(j, i + 1)[2] +
                  light.ucharPtr(j + 1, 0)[2] +
                  light.ucharPtr(j + 1, i)[2] +
                  light.ucharPtr(j + 1, i + 1)[2])
            }
            if (i != 0 && j == 0) {
              ground1 =
                (1.0 / 9) *
                (light.ucharPtr(j, i)[0] +
                  light.ucharPtr(j, i - 1)[0] +
                  light.ucharPtr(0, i)[0] +
                  light.ucharPtr(0, i + 1)[0] +
                  light.ucharPtr(j, i - 1)[0] +
                  light.ucharPtr(j, i + 1)[0] +
                  light.ucharPtr(j + 1, i - 1)[0] +
                  light.ucharPtr(j + 1, i)[0] +
                  light.ucharPtr(j + 1, i + 1)[0])
              ground2 =
                (1.0 / 9) *
                (light.ucharPtr(j, i)[1] +
                  light.ucharPtr(j, i - 1)[1] +
                  light.ucharPtr(0, i)[1] +
                  light.ucharPtr(0, i + 1)[1] +
                  light.ucharPtr(j, i - 1)[1] +
                  light.ucharPtr(j, i + 1)[1] +
                  light.ucharPtr(j + 1, i - 1)[1] +
                  light.ucharPtr(j + 1, i)[1] +
                  light.ucharPtr(j + 1, i + 1)[1])
              ground3 =
                (1.0 / 9) *
                (light.ucharPtr(j, i)[2] +
                  light.ucharPtr(j, i - 1)[2] +
                  light.ucharPtr(0, i)[2] +
                  light.ucharPtr(0, i + 1)[2] +
                  light.ucharPtr(j, i - 1)[2] +
                  light.ucharPtr(j, i + 1)[2] +
                  light.ucharPtr(j + 1, i - 1)[2] +
                  light.ucharPtr(j + 1, i)[2] +
                  light.ucharPtr(j + 1, i + 1)[2])
            }
            if (i != 0 && j != 0) {
              ground1 =
                (1.0 / 9) *
                (light.ucharPtr(j, i)[0] +
                  light.ucharPtr(j - 1, i - 1)[0] +
                  light.ucharPtr(j - 1, i)[0] +
                  light.ucharPtr(j - 1, i + 1)[0] +
                  light.ucharPtr(j, i - 1)[0] +
                  light.ucharPtr(j, i + 1)[0] +
                  light.ucharPtr(j + 1, i - 1)[0] +
                  light.ucharPtr(j + 1, i)[0] +
                  light.ucharPtr(j + 1, i + 1)[0])
              ground2 =
                (1.0 / 9) *
                (light.ucharPtr(j, i)[1] +
                  light.ucharPtr(j - 1, i - 1)[1] +
                  light.ucharPtr(j - 1, i)[1] +
                  light.ucharPtr(j - 1, i + 1)[1] +
                  light.ucharPtr(j, i - 1)[1] +
                  light.ucharPtr(j, i + 1)[1] +
                  light.ucharPtr(j + 1, i - 1)[1] +
                  light.ucharPtr(j + 1, i)[1] +
                  light.ucharPtr(j + 1, i + 1)[1])
              ground3 =
                (1.0 / 9) *
                (light.ucharPtr(j, i)[2] +
                  light.ucharPtr(j - 1, i - 1)[2] +
                  light.ucharPtr(j - 1, i)[2] +
                  light.ucharPtr(j - 1, i + 1)[2] +
                  light.ucharPtr(j, i - 1)[2] +
                  light.ucharPtr(j, i + 1)[2] +
                  light.ucharPtr(j + 1, i - 1)[2] +
                  light.ucharPtr(j + 1, i)[2] +
                  light.ucharPtr(j + 1, i + 1)[2])
            }
            //做差求图像细节
            detail1 = light.ucharPtr(j, i)[0] - ground1
            detail2 = light.ucharPtr(j, i)[1] - ground2
            detail3 = light.ucharPtr(j, i)[2] - ground3

            //叠加到红外灰度图上求得msx效果图像
            let dT1 = 1 * detail1 + this.midMat.ucharPtr(j, i)[0]
            let dT2 = 1 * detail2 + this.midMat.ucharPtr(j, i)[1]
            let dT3 = 1 * detail3 + this.midMat.ucharPtr(j, i)[2]

            let iT1 = parseInt(dT1)
            let iT2 = parseInt(dT2)
            let iT3 = parseInt(dT3)

            if (iT3 < 0) {
              iT3 = 0
            }
            if (iT3 > 255) {
              iT3 = 255
            }
            if (iT2 < 0) {
              iT2 = 0
            }
            if (iT2 > 255) {
              iT2 = 255
            }
            if (iT1 < 0) {
              iT1 = 0
            }
            if (iT1 > 255) {
              iT1 = 255
            }

            this.midMat.ucharPtr(j, i)[0] = iT1
            this.midMat.ucharPtr(j, i)[1] = iT2
            this.midMat.ucharPtr(j, i)[2] = iT3
          }
        }
      }
      console.timeEnd('imix')

      this.canvasMat = this.midMat.clone()
      this.styleChange()
    },
    // 画中画
    getPIP() {
      if (!this.isHaveImg) return

      const mat = this.mLightMat.clone()

      if (this.addMapcolor(this.pseudoColorNum)) {
        for (let j = 0.225 * this.midMat.rows; j < 0.775 * this.midMat.rows; j++) {
          for (let i = 0.23 * this.midMat.cols; i < 0.77 * this.midMat.cols; i++) {
            mat.ucharPtr(j, i)[0] = this.midMat.ucharPtr(j, i)[0]
            mat.ucharPtr(j, i)[1] = this.midMat.ucharPtr(j, i)[1]
            mat.ucharPtr(j, i)[2] = this.midMat.ucharPtr(j, i)[2]
          }
        }
        this.pipMat = mat.clone()
      }

      this.canvasMat = this.pipMat.clone()
      this.styleChange()
    },
    // 融合程度修改
    scaleChange() {
      this.combinIRandLight()
      this.canvasMat = this.midMat.clone()
      this.styleChange()
    },
    // 融合
    combinIRandLight() {
      if (!this.isHaveImg) return

      const scale = this.fusionScale

      if (this.addMapcolor(this.pseudoColorNum)) {
        for (let j = 0; j < this.midMat.rows; j++) {
          for (let i = 0; i < this.midMat.cols; i++) {
            this.midMat.ucharPtr(j, i)[0] = scale * this.mLightMat.ucharPtr(j, i)[0] + (1 - scale) * this.midMat.ucharPtr(j, i)[0]
            this.midMat.ucharPtr(j, i)[1] = scale * this.mLightMat.ucharPtr(j, i)[1] + (1 - scale) * this.midMat.ucharPtr(j, i)[1]
            this.midMat.ucharPtr(j, i)[2] = scale * this.mLightMat.ucharPtr(j, i)[2] + (1 - scale) * this.midMat.ucharPtr(j, i)[2]
          }
        }
      }
    },
    // 伪彩悬停
    colorOver(num) {
      if (!this.isHaveImg) return

      this.pseudoColorNum = num
      this.addMapcolor(num, true)
      this.showRightSlider = true
      this.canvasMat = this.midMat.clone()
      this.styleChange()
    },
    // 增加伪彩
    addMapcolor(iIndex, getColorFlag) {
      if (getColorFlag) {
        this.graduatedColors = []
      }
      if (this.params.gwType === 'PX1') {
        let iTypePallet = iIndex - 1
        this.midMat = this.mIRMat.clone()
        const recordIndex = Math.floor(this.midMat.rows / 5)
        for (let j = 0; j < this.midMat.rows; j++) {
          for (let i = 0; i < this.midMat.cols; i++) {
            //其他伪彩读取数据后转I420
            if (iTypePallet == 1) {
              //黑热
              this.midMat.ucharPtr(j, i)[0] = 255 - this.midMat.ucharPtr(j, i)[0]
              this.midMat.ucharPtr(j, i)[1] = 255 - this.midMat.ucharPtr(j, i)[1]
              this.midMat.ucharPtr(j, i)[2] = 255 - this.midMat.ucharPtr(j, i)[2]
              if (getColorFlag && i === 0 && (j % recordIndex === 0 || j === this.midMat.rows)) {
                this.graduatedColors.push({ r: this.midMat.ucharPtr(j, i)[0], g: this.midMat.ucharPtr(j, i)[1], b: this.midMat.ucharPtr(j, i)[2] })
              }
            }

            const a = this.midMat.ucharPtr(j, i)[0]
            this.midMat.ucharPtr(j, i)[0] = this.sebanNewList[iTypePallet * 1024 + a * 4 + 2]
            this.midMat.ucharPtr(j, i)[1] = this.sebanNewList[iTypePallet * 1024 + a * 4 + 1]
            this.midMat.ucharPtr(j, i)[2] = this.sebanNewList[iTypePallet * 1024 + a * 4 + 0]
            if (getColorFlag && i === 0 && (j % recordIndex === 0 || j === this.midMat.rows)) {
              this.graduatedColors.push({ r: this.midMat.ucharPtr(j, i)[0], g: this.midMat.ucharPtr(j, i)[1], b: this.midMat.ucharPtr(j, i)[2] })
            }
          }
        }

        return true
      } else {
        let iTypePallet = iIndex - 2
        if (iTypePallet >= 9) {
          iTypePallet -= 1
        }
        if (!this.mIRMat.data) {
          return false
        }
        this.midMat = this.mIRMat.clone()

        const recordIndex = Math.floor(this.midMat.rows / 5)

        if (iTypePallet === -2) {
          for (let j = 0; j < this.midMat.rows; j++) {
            for (let i = 0; i < this.midMat.cols; i++) {
              if (getColorFlag && i === 0 && (j % recordIndex === 0 || j === this.midMat.rows)) {
                this.graduatedColors.push({ r: this.midMat.ucharPtr(j, i)[0], g: this.midMat.ucharPtr(j, i)[1], b: this.midMat.ucharPtr(j, i)[2] })
              }
            }
          }
          return true
        }
        if (iTypePallet === -1) {
          // 黑热
          // 像素取反
          for (let j = 0; j < this.midMat.rows; j++) {
            for (let i = 0; i < this.midMat.cols; i++) {
              this.midMat.ucharPtr(j, i)[0] = 255 - this.midMat.ucharPtr(j, i)[0]
              this.midMat.ucharPtr(j, i)[1] = 255 - this.midMat.ucharPtr(j, i)[1]
              this.midMat.ucharPtr(j, i)[2] = 255 - this.midMat.ucharPtr(j, i)[2]
              if (getColorFlag && i === 0 && (j % recordIndex === 0 || j === this.midMat.rows)) {
                this.graduatedColors.push({ r: this.midMat.ucharPtr(j, i)[0], g: this.midMat.ucharPtr(j, i)[1], b: this.midMat.ucharPtr(j, i)[2] })
              }
            }
          }
          return true
        }

        for (let j = 0; j < this.midMat.rows; j++) {
          for (let i = 0; i < this.midMat.cols; i++) {
            const a = this.midMat.ucharPtr(j, i)[0]
            // mat.at<Vec3b>(j, i) B G R
            // mat.ucharPtr(j, i)  R G B
            this.midMat.ucharPtr(j, i)[0] = this.sebanNewList[iTypePallet * 1024 + (a * 3 + 0)]
            this.midMat.ucharPtr(j, i)[1] = this.sebanNewList[iTypePallet * 1024 + (a * 3 + 1)]
            this.midMat.ucharPtr(j, i)[2] = this.sebanNewList[iTypePallet * 1024 + (a * 3 + 2)]
            if (getColorFlag && i === 0 && (j % recordIndex === 0 || j === this.midMat.rows)) {
              this.graduatedColors.push({ r: this.midMat.ucharPtr(j, i)[0], g: this.midMat.ucharPtr(j, i)[1], b: this.midMat.ucharPtr(j, i)[2] })
            }
          }
        }
        return true
      }
    },
    // 温度展示位置改变
    styleChange() {
      if (this.currentFunct === '3D') {
        return
      }
      const isNormal = this.tempShowLocation === 'nrml'
      cv.imshow('canvas', this.getZoomMat(this.canvasMat))
      const ctx = this.getCtx()

      for (let i = 0; i < this.drawList.length; i++) {
        const draw = this.drawList[i]
        switch (draw.type) {
          // 点
          case 'point':
            {
              if (isNormal) {
                this.drawPoint(ctx, draw.newX, draw.newY, draw.name + '  ' + this.getTempFunc(this.imgInfo.temUnit)(draw.resultBak.avg))
              } else {
                this.drawPoint(ctx, draw.newX, draw.newY, draw.name)
              }
            }
            break

          // 线
          case 'line':
            {
              this.drawLine(ctx, draw.oldX, draw.oldY, draw.newX, draw.newY, draw.result, isNormal, true)
            }
            break

          // 矩形
          case 'rectangle':
            {
              this.drawRct(ctx, draw.oldX, draw.oldY, draw.newX, draw.newY, draw.result, isNormal, true)
            }
            break

          // 圆形
          case 'circle':
            {
              this.drawCircle(ctx, draw.oldX, draw.oldY, this.getRadius(draw.oldX, draw.oldY, draw.newX, draw.newY), draw.result, isNormal, true)
            }
            break

          // 多边形
          case 'polygon':
            {
              this.drawPolygon(ctx, draw.polyXYList, true, draw.result, isNormal, true)
            }
            break

          default:
            break
        }
      }
    },
    // 标记最大最小值
    drawTriangle(ctx, maxX, maxY, minX, minY) {
      maxX = this.getAfVal(maxX)
      maxY = this.getAfVal(maxY)
      minX = this.getAfVal(minX)
      minY = this.getAfVal(minY)

      ctx.beginPath()
      ctx.moveTo(maxX, maxY)
      ctx.lineTo(maxX + this.triSize, maxY - 2 * this.triSize)
      ctx.lineTo(maxX - this.triSize, maxY - 2 * this.triSize)
      ctx.closePath()
      ctx.fillStyle = '#0000ff'
      ctx.fill()

      ctx.beginPath()
      ctx.moveTo(minX, minY)
      ctx.lineTo(minX + this.triSize, minY + 2 * this.triSize)
      ctx.lineTo(minX - this.triSize, minY + 2 * this.triSize)
      ctx.closePath()
      ctx.fillStyle = '#ff0000'
      ctx.fill()
    },
    // 绘制点
    drawPoint(ctx, offsetX, offsetY, detail) {
      offsetX = this.getAfVal(offsetX)
      offsetY = this.getAfVal(offsetY)

      // 绘制点
      ctx.beginPath()
      ctx.arc(offsetX, offsetY, 2, 0, Math.PI * 2)
      ctx.fillStyle = this.fontColor
      ctx.fill()

      // 绘制文字
      ctx.font = `${this.fontSize}px "微软雅黑"`
      ctx.fillText(detail, offsetX, offsetY + 10)
    },
    // 绘制线
    drawLine(ctx, oldX, oldY, newX, newY, result, isNormal, needZoom) {
      if (needZoom) {
        oldX = this.getAfVal(oldX)
        oldY = this.getAfVal(oldY)
        newX = this.getAfVal(newX)
        newY = this.getAfVal(newY)
      }

      ctx.beginPath()
      ctx.moveTo(oldX, oldY)
      ctx.lineTo(newX, newY)
      ctx.strokeStyle = this.fontColor
      ctx.stroke()

      if (result) {
        const { name, avg, min, max, maxX, maxY, minX, minY } = result
        // 标记最大最小值
        this.drawTriangle(ctx, maxX, maxY, minX, minY)

        // 绘制文字
        ctx.fillStyle = this.fontColor
        ctx.font = `${this.fontSize}px "微软雅黑"`
        let detail = name
        ctx.fillText(detail, newX, newY + this.fontSize)
        if (isNormal) {
          let count = 2
          if (this.tempTypeCheckList.indexOf('avg') !== -1) {
            detail = 'AVG: ' + avg
            ctx.fillText(detail, newX, newY + this.fontSize * count)
            count++
          }
          if (this.tempTypeCheckList.indexOf('max') !== -1) {
            detail = 'MAX: ' + max
            ctx.fillText(detail, newX, newY + this.fontSize * count)
            count++
          }
          if (this.tempTypeCheckList.indexOf('min') !== -1) {
            detail = 'MIN: ' + min
            ctx.fillText(detail, newX, newY + this.fontSize * count)
          }
        }
      }
    },
    // 绘制矩形
    drawRct(ctx, oldX, oldY, newX, newY, result, isNormal, needZoom) {
      if (needZoom) {
        oldX = this.getAfVal(oldX)
        oldY = this.getAfVal(oldY)
        newX = this.getAfVal(newX)
        newY = this.getAfVal(newY)
      }

      ctx.beginPath()
      ctx.rect(oldX, oldY, newX - oldX, newY - oldY)
      ctx.strokeStyle = this.fontColor
      ctx.stroke()

      if (result) {
        const { name, avg, min, max, maxX, maxY, minX, minY } = result
        // 标记最大最小值
        this.drawTriangle(ctx, maxX, maxY, minX, minY)

        // 绘制文字
        const textX = (newX + oldX) / 2
        const textY = (newY + oldY) / 2

        ctx.fillStyle = this.fontColor
        ctx.font = `${this.fontSize}px "微软雅黑"`
        let detail = name
        ctx.fillText(detail, textX, textY + this.fontSize)
        if (isNormal) {
          let count = 2
          if (this.tempTypeCheckList.indexOf('avg') !== -1) {
            detail = 'AVG: ' + avg
            ctx.fillText(detail, textX, textY + this.fontSize * count)
            count++
          }
          if (this.tempTypeCheckList.indexOf('max') !== -1) {
            detail = 'MAX: ' + max
            ctx.fillText(detail, textX, textY + this.fontSize * count)
            count++
          }
          if (this.tempTypeCheckList.indexOf('min') !== -1) {
            detail = 'MIN: ' + min
            ctx.fillText(detail, textX, textY + this.fontSize * count)
          }
        }
      }
    },
    // 绘制圆形
    drawCircle(ctx, centerX, centerY, radius, result, isNormal, needZoom) {
      if (needZoom) {
        centerX = this.getAfVal(centerX)
        centerY = this.getAfVal(centerY)
        radius = this.getAfVal(radius)
      }

      ctx.beginPath()
      ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI)
      ctx.strokeStyle = this.fontColor
      ctx.stroke()

      if (result) {
        const { name, avg, min, max, maxX, maxY, minX, minY } = result
        // 标记最大最小值
        this.drawTriangle(ctx, maxX, maxY, minX, minY)

        // 绘制文字
        ctx.fillStyle = this.fontColor
        ctx.font = `${this.fontSize}px "微软雅黑"`
        let detail = name
        ctx.fillText(detail, centerX, centerY)
        if (isNormal) {
          let count = 1
          if (this.tempTypeCheckList.indexOf('avg') !== -1) {
            detail = 'AVG: ' + avg
            ctx.fillText(detail, centerX, centerY + this.fontSize * count)
            count++
          }
          if (this.tempTypeCheckList.indexOf('max') !== -1) {
            detail = 'MAX: ' + max
            ctx.fillText(detail, centerX, centerY + this.fontSize * count)
            count++
          }
          if (this.tempTypeCheckList.indexOf('min') !== -1) {
            detail = 'MIN: ' + min
            ctx.fillText(detail, centerX, centerY + this.fontSize * count)
          }
        }
      }
    },
    // 绘制多边形
    drawPolygon(ctx, polyXYList, isEnd, result, isNormal, needZoom) {
      const xyList = JSON.parse(JSON.stringify(polyXYList))

      if (needZoom) {
        xyList.map((item) => {
          item.x = this.getAfVal(item.x)
          item.y = this.getAfVal(item.y)
        })
      }

      ctx.beginPath()
      ctx.moveTo(xyList[0].x, xyList[0].y)
      for (let i = 1; i < xyList.length; i++) {
        ctx.lineTo(xyList[i].x, xyList[i].y)
      }
      if (isEnd) {
        ctx.closePath()
      }
      ctx.strokeStyle = this.fontColor
      ctx.stroke()

      if (result) {
        const { name, avg, min, max, maxX, maxY, minX, minY } = result
        // 标记最大最小值
        this.drawTriangle(ctx, maxX, maxY, minX, minY)

        // 绘制文字
        const xList = xyList.map((item) => {
          return item.x
        })
        const yList = xyList.map((item) => {
          return item.y
        })

        const textX = (Math.max(...xList) + Math.min(...xList)) / 2
        const textY = (Math.max(...yList) + Math.min(...yList)) / 2

        ctx.fillStyle = this.fontColor
        ctx.font = `${this.fontSize}px "微软雅黑"`
        let detail = name
        ctx.fillText(detail, textX, textY)
        if (isNormal) {
          let count = 1
          if (this.tempTypeCheckList.indexOf('avg') !== -1) {
            detail = 'AVG: ' + avg
            ctx.fillText(detail, textX, textY + this.fontSize * count)
            count++
          }
          if (this.tempTypeCheckList.indexOf('max') !== -1) {
            detail = 'MAX: ' + max
            ctx.fillText(detail, textX, textY + this.fontSize * count)
            count++
          }
          if (this.tempTypeCheckList.indexOf('min') !== -1) {
            detail = 'MIN: ' + min
            ctx.fillText(detail, textX, textY + this.fontSize * count)
          }
        }
      }
    },
    // 鼠标点击
    mouseClick(e) {
      if (!this.isHaveImg) return
      this.offsetX = this.getBefVal(e.offsetX)
      this.offsetY = this.getBefVal(e.offsetY)
      switch (this.currentFunct) {
        case 'point':
          {
            const ctx = this.getCtx()
            // 获取温度
            const temp = getPointTemp(this.offsetX, this.offsetY, this.pTempBufferDst, this.params.cProType, this.params.iwidth).avg

            // 获取文字描述
            const name = 'P' + this.pointCount
            // 绘制文字
            if (this.tempShowLocation === 'nrml') {
              this.drawPoint(ctx, this.offsetX, this.offsetY, name + '  ' + this.getTempFunc(this.imgInfo.temUnit)(temp))
            } else {
              this.drawPoint(ctx, this.offsetX, this.offsetY, name)
            }

            // 计数器——点
            this.pointCount++

            this.drawList.push({
              type: 'point',
              name,
              max: temp,
              min: temp,
              avg: temp,
              newX: this.offsetX,
              newY: this.offsetY,
              resultBak: { max: temp, min: temp, avg: temp }
            })
          }
          break

        case 'polygon':
          {
            // 已经开始绘制
            if (this.startFlag) {
              cv.imshow('canvas', this.newMat)
            } else {
              this.startFlag = true
              this.newMat = this.getNewMat()
            }

            const ctx = this.getCtx()
            this.polygonXYList.push({
              x: e.offsetX,
              y: e.offsetY
            })
            this.drawPolygon(ctx, this.polygonXYList)
          }
          break

        default:
          break
      }
    },
    // 鼠标按下
    mouseDown(e) {
      if (!this.isHaveImg) return
      // 控制左键触发
      if (e.button !== 0) return
      this.offsetX = e.offsetX
      this.offsetY = e.offsetY
      switch (this.currentFunct) {
        case 'line':
          {
            this.newMat = this.getNewMat()
            this.startFlag = true
          }
          break

        case 'rectangle':
          {
            this.newMat = this.getNewMat()
            this.startFlag = true
          }
          break

        case 'circle':
          {
            this.newMat = this.getNewMat()
            this.startFlag = true
          }
          break

        default:
          break
      }
    },
    // 获取当前的画布数据
    getNewMat() {
      const canvas = document.getElementById('canvas')

      if (canvas) {
        const ctx = canvas.getContext('2d')
        const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height)
        return cv.matFromImageData(imgData)
      } else {
        this.$message.error('获取画布失败')
      }
    },
    // 获取缩放前的值
    getBefVal(val) {
      return parseInt(val / this.zoom)
    },
    // 获取缩放后的值
    getAfVal(val) {
      return parseInt(val * this.zoom)
    },
    // 缩放结果
    getZoomMat(srcMat) {
      const dstMat = new cv.Mat()
      cv.resize(srcMat, dstMat, new cv.Size(), this.zoom, this.zoom, cv.INTER_LANCZOS4)
      return dstMat
    },
    // 滑轮滚动
    mouseWheel(e) {
      let changeFlag = false

      if (e.deltaY > 0 && this.zoom > 0.25) {
        // 下滚 缩小
        this.zoom -= 0.25
        changeFlag = true
      } else if (e.deltaY < 0 && this.zoom < 1.75) {
        // 上滚 放大
        this.zoom += 0.25
        changeFlag = true
      }

      if (changeFlag) {
        this.styleChange()

        const mainImg = document.getElementById('main-img').getBoundingClientRect()
        const outWidth = mainImg.width
        const outHeight = mainImg.height

        const relateDiv = document.getElementById('relate-div').getBoundingClientRect()
        const inWidth = relateDiv.width
        const inHeight = relateDiv.height

        this.minWidth = outWidth > inWidth ? '100%' : inWidth + 'px'
        this.minHeight = outHeight > inHeight ? '100%' : inHeight + 'px'
      }
    },
    // 鼠标滑动
    mouseMove(e) {
      if (!this.isHaveImg) return

      switch (this.currentFunct) {
        case 'select':
          {
            this.showSelectedTemp = true
            this.offsetX = e.offsetX
            this.offsetY = e.offsetY
            // this.debounceFn()
            this.currentTemp = getPointTemp(
              this.getBefVal(this.offsetX),
              this.getBefVal(this.offsetY),
              this.pTempBufferDst,
              this.params.cProType,
              this.params.iwidth
            ).avg
          }
          break

        case 'line':
          {
            if (this.startFlag) {
              const ctx = this.getCtx()
              cv.imshow('canvas', this.newMat)
              this.drawLine(ctx, this.offsetX, this.offsetY, e.offsetX, e.offsetY)
            }
          }
          break

        case 'rectangle':
          {
            if (this.startFlag) {
              const ctx = this.getCtx()
              cv.imshow('canvas', this.newMat)
              this.drawRct(ctx, this.offsetX, this.offsetY, e.offsetX, e.offsetY)
            }
          }
          break

        case 'circle':
          {
            if (this.startFlag) {
              const ctx = this.getCtx()
              cv.imshow('canvas', this.newMat)
              this.drawCircle(ctx, this.offsetX, this.offsetY, this.getRadius(this.offsetX, this.offsetY, e.offsetX, e.offsetY, true))
            }
          }
          break

        default:
          break
      }
    },
    // 鼠标抬起
    mouseUp(e) {
      if (!this.isHaveImg) return

      // 起始相同不绘制
      if (this.offsetX === e.offsetX && this.offsetY === e.offsetY) {
        this.startFlag = false
        return
      }

      const isNormal = this.tempShowLocation === 'nrml'
      const beforeStartX = this.getBefVal(this.offsetX)
      const beforeStartY = this.getBefVal(this.offsetY)
      const beforeEndX = this.getBefVal(e.offsetX)
      const beforeEndY = this.getBefVal(e.offsetY)

      switch (this.currentFunct) {
        case 'line':
          {
            this.startFlag = false
            const ctx = this.getCtx()
            cv.imshow('canvas', this.newMat)
            const result = getLineTemp(
              beforeStartX,
              beforeStartY,
              beforeEndX,
              beforeEndY,
              this.pTempBufferDst,
              this.params.cProType,
              this.params.iwidth,
              this.params.iheight
            )
            result.name = 'L' + this.linCount

            const getTemp = this.getTempFunc(this.imgInfo.temUnit)
            const { max, min, avg } = result
            result.avg = getTemp(avg)
            result.max = getTemp(max)
            result.min = getTemp(min)

            this.drawLine(ctx, beforeStartX, beforeStartY, beforeEndX, beforeEndY, result, isNormal, true)

            this.linCount++
            this.drawList.push({
              name: result.name,
              type: 'line',
              oldX: beforeStartX,
              oldY: beforeStartY,
              newX: beforeEndX,
              newY: beforeEndY,
              result,
              resultBak: { max, min, avg }
            })
          }
          break

        case 'rectangle':
          {
            if (this.startFlag) {
              this.startFlag = false
              const ctx = this.getCtx()
              cv.imshow('canvas', this.newMat)
              const result = getRctTemp(
                beforeStartX,
                beforeStartY,
                beforeEndX,
                beforeEndY,
                this.pTempBufferDst,
                this.params.cProType,
                this.params.iwidth,
                this.params.iheight
              )
              result.name = 'R' + this.rctCount

              const getTemp = this.getTempFunc(this.imgInfo.temUnit)
              const { max, min, avg } = result
              result.avg = getTemp(avg)
              result.max = getTemp(max)
              result.min = getTemp(min)

              this.drawRct(ctx, beforeStartX, beforeStartY, beforeEndX, beforeEndY, result, isNormal, true)

              this.rctCount++
              this.drawList.push({
                name: result.name,
                type: 'rectangle',
                oldX: beforeStartX,
                oldY: beforeStartY,
                newX: beforeEndX,
                newY: beforeEndY,
                result,
                resultBak: { max, min, avg }
              })
            }
          }
          break

        case 'circle':
          {
            if (this.startFlag) {
              this.startFlag = false
              const ctx = this.getCtx()
              cv.imshow('canvas', this.newMat)
              const radius = this.getRadius(beforeStartX, beforeStartY, beforeEndX, beforeEndY)
              const result = getCircleTemp(beforeStartX, beforeStartY, radius, this.pTempBufferDst, this.params.cProType, this.params.iwidth)
              result.name = 'C' + this.circleCount

              const getTemp = this.getTempFunc(this.imgInfo.temUnit)
              const { max, min, avg } = result
              result.avg = getTemp(avg)
              result.max = getTemp(max)
              result.min = getTemp(min)

              this.drawCircle(ctx, beforeStartX, beforeStartY, radius, result, isNormal, true)

              this.circleCount++
              this.drawList.push({
                name: result.name,
                type: 'circle',
                oldX: beforeStartX,
                oldY: beforeStartY,
                newX: beforeEndX,
                newY: beforeEndY,
                result,
                resultBak: { max, min, avg }
              })
            }
          }
          break

        default:
          break
      }
    },
    // 鼠标移出
    mouseOut(e) {
      if (!this.isHaveImg) return

      // 起始相同不绘制
      if (this.offsetX === e.offsetX && this.offsetY === e.offsetY) {
        this.startFlag = false
        return
      }

      const isNormal = this.tempShowLocation === 'nrml'
      const beforeStartX = this.getBefVal(this.offsetX)
      const beforeStartY = this.getBefVal(this.offsetY)
      const beforeEndX = this.getBefVal(e.offsetX)
      const beforeEndY = this.getBefVal(e.offsetY)

      switch (this.currentFunct) {
        case 'select':
          {
            this.showSelectedTemp = false
          }
          break
        case 'line':
          {
            if (this.startFlag) {
              this.startFlag = false
              const ctx = this.getCtx()
              cv.imshow('canvas', this.newMat)
              const result = getLineTemp(
                beforeStartX,
                beforeStartY,
                beforeEndX,
                beforeEndY,
                this.pTempBufferDst,
                this.params.cProType,
                this.params.iwidth,
                this.params.iheight
              )
              result.name = 'L' + this.linCount

              const getTemp = this.getTempFunc(this.imgInfo.temUnit)
              const { max, min, avg } = result
              result.avg = getTemp(avg)
              result.max = getTemp(max)
              result.min = getTemp(min)

              this.drawLine(ctx, beforeStartX, beforeStartY, beforeEndX, beforeEndY, result, isNormal, true)

              this.linCount++
              this.drawList.push({
                name: result.name,
                type: 'line',
                oldX: beforeStartX,
                oldY: beforeStartY,
                newX: beforeEndX,
                newY: beforeEndY,
                result,
                resultBak: { max, min, avg }
              })
            }
          }
          break

        case 'rectangle':
          {
            if (this.startFlag) {
              this.startFlag = false
              const ctx = this.getCtx()
              cv.imshow('canvas', this.newMat)
              const result = getRctTemp(
                beforeStartX,
                beforeStartY,
                beforeEndX,
                beforeEndY,
                this.pTempBufferDst,
                this.params.cProType,
                this.params.iwidth,
                this.params.iheight
              )
              result.name = 'R' + this.rctCount

              const getTemp = this.getTempFunc(this.imgInfo.temUnit)
              const { max, min, avg } = result
              result.avg = getTemp(avg)
              result.max = getTemp(max)
              result.min = getTemp(min)

              this.drawRct(ctx, beforeStartX, beforeStartY, beforeEndX, beforeEndY, result, isNormal, true)

              this.rctCount++
              this.drawList.push({
                name: result.name,
                type: 'rectangle',
                oldX: beforeStartX,
                oldY: beforeStartY,
                newX: beforeEndX,
                newY: beforeEndY,
                result,
                resultBak: { max, min, avg }
              })
            }
          }
          break

        case 'circle':
          {
            if (this.startFlag) {
              this.startFlag = false
              const ctx = this.getCtx()
              cv.imshow('canvas', this.newMat)
              const radius = this.getRadius(beforeStartX, beforeStartY, beforeEndX, beforeEndY)
              const result = getCircleTemp(beforeStartX, beforeStartY, radius, this.pTempBufferDst, this.params.cProType, this.params.iwidth)
              result.name = 'C' + this.circleCount

              const getTemp = this.getTempFunc(this.imgInfo.temUnit)
              const { max, min, avg } = result
              result.avg = getTemp(avg)
              result.max = getTemp(max)
              result.min = getTemp(min)

              this.drawCircle(ctx, beforeStartX, beforeStartY, radius, result, isNormal, true)

              this.circleCount++
              this.drawList.push({
                name: result.name,
                type: 'circle',
                oldX: beforeStartX,
                oldY: beforeStartY,
                newX: beforeEndX,
                newY: beforeEndY,
                result,
                resultBak: { max, min, avg }
              })
            }
          }
          break

        default:
          break
      }
    },
    // 鼠标右击
    contextMenu() {
      if (!this.isHaveImg) return
      const isNormal = this.tempShowLocation === 'nrml'

      switch (this.currentFunct) {
        case 'polygon':
          {
            // 结束绘制
            if (this.startFlag) {
              this.startFlag = false
              const ctx = this.getCtx()
              cv.imshow('canvas', this.newMat)
              this.polygonXYList.map((item) => {
                item.x = this.getBefVal(item.x)
                item.y = this.getBefVal(item.y)
              })
              const result = getPolyTemp(this.polygonXYList, this.pTempBufferDst, this.params.cProType, this.params.iwidth, this.params.iheight)
              result.name = 'G' + this.polyCount

              const getTemp = this.getTempFunc(this.imgInfo.temUnit)
              const { max, min, avg } = result
              result.avg = getTemp(avg)
              result.max = getTemp(max)
              result.min = getTemp(min)

              this.drawPolygon(ctx, this.polygonXYList, true, result, isNormal, true)

              this.polyCount++
              this.drawList.push({
                name: result.name,
                type: 'polygon',
                polyXYList: JSON.parse(JSON.stringify(this.polygonXYList)),
                result,
                resultBak: { max, min, avg }
              })

              this.polygonXYList = []
            }
          }
          break

        default:
          break
      }
    },
    // // 取鼠标处温度
    // getMouseTemp() {
    //     this.currentTemp =
    //         (this.pTempBufferDst[this.offsetY * this.params.iwidth + this.offsetX] - 2731.5) /
    //         10
    // },
    // 求半径
    getRadius(oldX, oldY, newX, newY, needZoom) {
      let radius = Math.sqrt(Math.pow(newX - oldX, 2) + Math.pow(newY - oldY, 2))
      const width = needZoom ? this.params.iwidth * this.zoom : this.params.iwidth
      const height = needZoom ? this.params.iheight * this.zoom : this.params.iheight

      // 判断是否越界
      // 左越界
      if (oldX - radius < 0) {
        radius = oldX
      }
      // 右越界
      if (oldX + radius > width) {
        radius = width - oldX
      }
      // 上越界
      if (oldY - radius < 0) {
        radius = oldY
      }
      // 下越界
      if (oldY + radius > height) {
        radius = height - oldY
      }

      return radius
    },
    // 切换功能
    functClick(funct) {
      if (!this.isHaveImg) return
      if (this.currentFunct === funct && ['csv', 'word', 'pdf', 'font'].indexOf(funct) === -1) return

      this.currentFunct = funct

      this.$nextTick(() => {
        // 是否保留右滑块
        if (['pseudoColor', 'fusion', 'PIP', 'iMix'].indexOf(funct) === -1) {
          this.showRightSlider = false
        }

        switch (funct) {
          case 'font':
            {
              this.fontDialogVisible = true
            }
            break

          // 红外
          case 'infrared':
            {
              this.canvasMat = this.mIRMat.clone()
              this.styleChange()
            }
            break

          // 可见光
          case 'visibleLight':
            {
              this.canvasMat = this.mLightMat.clone()
              this.styleChange()
            }
            break

          // 融合
          case 'fusion':
            {
              this.scaleChange()
            }
            break

          // 画中画
          case 'PIP':
            {
              this.getPIP()
            }
            break

          // iMix
          case 'iMix':
            {
              this.getImix()
            }
            break

          // 3D
          case '3D':
            {
              this.get3D()
            }
            break

          // csv
          case 'csv':
            {
              this.getCsv()
            }
            break

          // word
          case 'word':
            {
              this.getWord()
            }
            break

          // pdf
          case 'pdf':
            {
              this.getPdf()
            }
            break

          default:
            this.styleChange()
            break
        }
      })
    },
    // pdf
    getPdf() {
      this.$prompt('请输入备注', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
        // inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
        // inputErrorMessage: '邮箱格式不正确'
      })
        .then(({ value }) => {
          // 图像信息
          this.timeNow = timeFormat()
          cv.imshow('pdf-ir', this.getNewMat())
          if (this.params.gwType !== 'PX1') {
            cv.imshow('pdf-light', this.mLightMat)
          }

          const tempUnitName = this.tempUnitName
          // 区域信息
          const roiList = []
          this.drawList.forEach((item) => {
            if (item.type === 'point') {
              const { name, max, min, avg } = item
              roiList.push({ name, max: max + tempUnitName, min: min + tempUnitName, avg: avg + tempUnitName })
            } else {
              const { name, result } = item
              roiList.push({ name, max: result.max + tempUnitName, min: result.min + tempUnitName, avg: result.avg + tempUnitName })
            }
          })
          // 处理环境参数
          this.pdfObj = {
            fReflectTemp: this.reflectTemp + tempUnitName,
            fAirTemp: this.airTemp + tempUnitName,
            fHumidity: this.imgInfo.envObj.fHumidity,
            fEmiss: this.imgInfo.envObj.fEmiss,
            fDistance: this.distance + this.disUnitName,
            roiList,
            remark: value || ''
          }

          this.$nextTick(() => {
            exportPdf()
          })
        })
        .catch(() => {})
    },
    // word
    getWord() {
      this.$prompt('请输入备注', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
        // inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
        // inputErrorMessage: '邮箱格式不正确'
      })
        .then(({ value }) => {
          // 获取base64的图片
          let imageIR = undefined,
            imageLight = undefined
          const canvas = document.createElement('canvas')
          cv.imshow(canvas, this.getNewMat())
          imageIR = canvas.toDataURL()
          if (this.params.gwType !== 'PX1') {
            cv.imshow(canvas, this.mLightMat)
            imageLight = canvas.toDataURL()
          }

          const tempUnitName = this.tempUnitName
          // 区域信息
          const roiList = []
          this.drawList.forEach((item) => {
            if (item.type === 'point') {
              const { name, max, min, avg } = item
              roiList.push({ name, max: max + tempUnitName, min: min + tempUnitName, avg: avg + tempUnitName })
            } else {
              const { name, result } = item
              roiList.push({ name, max: result.max + tempUnitName, min: result.min + tempUnitName, avg: result.avg + tempUnitName })
            }
          })

          // 处理环境参数
          const { sSerialName, iwidth, iheight, fileName } = this.params
          const params = {
            sSerialName,
            iwidth,
            iheight,
            fReflectTemp: this.reflectTemp + tempUnitName,
            fAirTemp: this.airTemp + tempUnitName,
            fHumidity: this.imgInfo.envObj.fHumidity,
            fEmiss: this.imgInfo.envObj.fEmiss,
            fDistance: this.distance + this.disUnitName,
            fileName
          }

          exportWord(params, { imageIR, imageLight, roiList, remark: value || '' })
        })
        .catch(() => {})
    },
    // csv
    getCsv() {
      // 懒加载该用法
      import('@/utils/Export2Excel').then((excel) => {
        // 构造数据
        const data = [
          ['参数：', '反射温度：', this.reflectTemp],
          ['', '大气环境温度：', this.airTemp],
          ['', '大气透过率：', this.imgInfo.envObj.fHumidity],
          ['', '发射率：', this.imgInfo.envObj.fEmiss],
          ['', '距离：', this.distance],
          ['', '']
        ]

        const getTemp = this.getTempFunc(this.imgInfo.temUnit)

        let firstFlag = true
        for (let j = 0; j < this.params.iheight; j++) {
          // 单独处理第一行
          const index = 6 + j
          if (j === 0) {
            data[index] = ['帧1']
          } else {
            data[index] = ['']
          }
          // 高 下标
          data[index].push(j)
          for (let k = 0; k < this.params.iwidth; k++) {
            // 宽 下标
            if (firstFlag) {
              data[5].push(k)
            }

            // 温度
            data[index].push(getTemp(getPointTemp(k, j, this.pTempBufferDst, this.params.cProType, this.params.iwidth).avg))
          }
          firstFlag = false
        }

        // 调用我们封装好的方法进行导出Excel
        excel.export_json_to_excel({
          // 导出的内容
          data,
          // 导出的文件名称
          filename: '测温数据',
          // 导出的表格宽度是否自动
          autoWidth: true,
          // 导出文件的后缀类型
          bookType: 'xlsx'
        })
      })
    },
    // 3D
    get3D() {
      const threeDList = []
      let max = 0
      let min = 0

      for (let i = 0; i < this.params.iwidth; i++) {
        for (let j = 0; j < this.params.iheight; j++) {
          const temp = getPointTemp(i, j, this.pTempBufferDst, this.params.cProType, this.params.iwidth).avg
          if (i === 0 && j === 0) {
            max = temp
            min = temp
          } else {
            if (temp > max) {
              max = temp
            }
            if (temp < min) {
              min = temp
            }
          }

          threeDList.push([i, j, temp])
        }
      }

      this.dialogVisible = true

      this.$nextTick(() => {
        this.threeDChart = echarts.init(document.getElementById('threeD-div'))
        this.threeDChart.setOption({
          tooltip: {},
          backgroundColor: '#fff',
          dataShape: [this.params.iwidth, this.params.iheight],
          visualMap: {
            show: false,
            dimension: 2, // 取温度维度
            min,
            max,
            inRange: {
              color: ['#313695', '#4575b4', '#74add1', '#abd9e9', '#e0f3f8', '#ffffbf', '#fee090', '#fdae61', '#f46d43', '#d73027', '#a50026']
            }
          },
          xAxis3D: {
            type: 'value',
            name: '宽'
          },
          yAxis3D: {
            type: 'value',
            name: '高'
          },
          zAxis3D: {
            type: 'value',
            name: '温度',
            scale: true
          },
          grid3D: {},
          series: [
            {
              type: 'surface',
              name: '',
              wireframe: {
                // show: false
              },
              data: threeDList
            }
          ]
        })
      })
    },
    // 获取画布
    getCtx() {
      const canvas = document.getElementById('canvas')

      if (canvas) {
        return canvas.getContext('2d')
      } else {
        this.$message.error('获取画布失败')
      }
    },
    // 读取图片
    fileChange(e) {
      // 重置数据
      this.drawList = []

      const reader = new FileReader()
      reader.readAsArrayBuffer(e.target.files[0])
      reader.onload = (e) => {
        const list = new Uint8Array(e.target.result)
        // 整合图像信息
        this.integrateImgInfo(getParams({ isGW: false, irgU8Array: list }))
      }
    },
    // unit8转unit16
    bytesTouint16(value) {
      const a = new Uint16Array(1)

      a[0] = (value[1] & 0xff) << 8

      a[0] += value[0] & 0xff

      return a[0]
    },

    // 获取数据
    getImgData(url, type, resolve) {
      axios
        .get((url.indexOf('aliyuncs') !== -1 ? '/alImg' : '/dfsImg') + url.substr(findStr(url, '/', 2)), {
          responseType: 'blob'
        })
        .then((res) => {
          const blob = new Blob([res.data])
          const reader = new FileReader()
          reader.readAsArrayBuffer(blob)
          reader.onload = (e) => {
            const list = new Uint8Array(e.target.result)
            if (type === 'ir') {
              this.irList = list
              resolve()
            } else if (type === 'dc') {
              this.dcList = list
              resolve()
            } else if (type === 'irg') {
              this.irgList = list

              // 整合图像信息
              this.integrateImgInfo(getParams({ isGW: false, irgU8Array: list }))
            }
          }
        })
        .catch((err) => {
          console.log('err => ', err)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
#navigation {
  width: 100%;
  height: 40px;
  background: #f4f4f4;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: bold;
}
.main-area {
  height: 100%;
  #img-div {
    box-sizing: border-box;
    height: 100%;
    position: relative;
    .main-left {
      position: absolute;
      left: 0;
      top: 0;
      width: 120px;
      height: 100%;
      overflow: auto;
      z-index: 1;
      direction: rtl;
      unicode-bidi: bidi-override;
      text-align: left;
      padding-left: 10px;
    }
    #main-top {
      position: absolute;
      left: 25%;
      top: 0;
      width: 60%;
      height: 64px;
      overflow: auto;
      z-index: 1;
    }
    #main-img {
      height: 85%;
      width: 85%;
      position: absolute;
      top: 10%;
      left: 11%;
      overflow: auto;
      .content {
        display: flex;
        display: -webkit-flex;
        align-items: center;
        justify-content: center;
        #relate-div {
          position: relative;
          .temp {
            position: absolute;
          }
        }
      }
    }
    #main-right {
      position: absolute;
      right: 0;
      height: 100%;
      display: flex;
      display: -webkit-flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      #refresh-btn {
        width: 18px;
        height: 18px;
        display: flex;
        display: -webkit-flex;
        justify-content: space-around;
        align-items: center;
        cursor: pointer;
        #btn-img {
          width: 14px;
          height: 14px;
          background: url('../assets/img/analysis/functionIcon/refresh.png') no-repeat;
          background-size: contain;
          -webkit-background-size: contain;
        }
        #btn-img:hover {
          width: 100%;
          height: 100%;
        }
      }

      #right-slider-outer {
        width: 22px;
        height: 85%;
        display: flex;
        flex-direction: column;
        align-items: center;
        #right-slider-top {
          width: 12px;
          border-radius: 8px 8px 0 0;
        }
        #right-slider-center {
          width: 12px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          .slider-btn {
            width: 20px;
            height: 6px;
            border-radius: 6px;
            border: 2px solid #71757a;
            cursor: pointer;
            background: #ffffff;
          }
          .slider-btn:hover {
            width: 22px;
            height: 7px;
            border: 2px solid #5053dd;
          }
        }
        #right-slider-bottom {
          width: 12px;
          border-radius: 0 0 8px 8px;
        }
      }
    }
    .main-bottom {
      position: absolute;
      left: 25%;
      bottom: 0;
      width: 50%;
    }
  }
}
.info-aside {
  overflow: auto;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 0px rgba(168, 171, 179, 0.2);
  border-radius: 8px;

  ::v-deep .el-input__inner {
    height: 40px;
  }
  .form-class {
    margin-left: 15px;
  }
  .fontDialog {
    box-shadow: 0px 2px 4px 0px rgba(168, 171, 179, 0.2);
    border-radius: 8px;
  }
  #param-btn {
    display: flex;
    display: -webkit-flex;
    justify-content: space-around;
  }
}
::v-deep .el-form-item {
  margin-bottom: 15px;
}
.el-divider__text {
  font-weight: bold;
}
.el-divider--horizontal {
  background: #5053dd;
  margin: 24px auto;
  width: 90%;
}
::v-deep .el-form-item__content {
  margin-right: 20px;
  box-sizing: border-box;
}
// .button-line {
//   display: flex;
//   display: -webkit-flex; /* Safari */
// }
.button-img {
  width: 30px;
  height: 30px;
  padding: 6px 8px;
  cursor: pointer;
}
.el-tabs--border-card {
  border: none;
}
::v-deep .el-tabs__content {
  height: calc(100% - 70px);
  overflow: auto;
}
.popover-content {
  max-height: 90vh;
  overflow: auto;
  .pseudo-color {
    cursor: pointer;
    text-align: center;
  }
}
#pdfDom {
  box-sizing: border-box;
  width: 95%;
  padding: 0 30px;
  position: absolute;
  bottom: -450%;
  div {
    margin-bottom: 20px;
  }
  .pdf-title {
    text-align: center;
    font-size: 30px;
  }
  .s-title {
    font-weight: bold;
  }
  .canvas-div {
    text-align: center;
    width: 100%;
  }
  .bottom-text {
    text-align: center;
  }
}
.aside-chart {
  height: calc((100% - 200px) / 2);
  overflow: hidden;
}
::v-deep .el-input-number {
  width: 100%;
}
.back-span {
  font-size: 12px;
  color: #5053dd;
  cursor: pointer;
}
::v-deep .el-tabs--border-card > .el-tabs__content {
  padding: 15px 0;
}
#threeD-div {
  height: 50vh;
}
</style>

<style lang="scss">
.el-popover {
  min-width: 50px;
}
.analysis-font-dialog {
  width: 520px;
  height: 325px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
</style>
